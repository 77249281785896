import React, { useState, useEffect } from "react";
import { DialogTitle, Dialog, DialogContent, DialogActions, TextField } from "@material-ui/core";
import ReactQuill from "react-quill";

export const RuleDialogForm = ({ open, onclose, title, submit, rule }) => {

  const [position, setPosition] = useState(1);
  const [pt, setPt] = useState("");
  const [en, setEn] = useState("");

  useEffect(() => {
    if (rule) {
      setPosition(rule.position);
      setPt(rule.text.pt);
      setEn(rule.text.gb);
    }
  }, [rule])

  const quillModules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['link'],
    ],
  }



  return (
    <Dialog
      open={open}
      onClose={onclose}
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <TextField
          variant="outlined"
          label="Posição"
          type="number"
          fullWidth
          className="mb-3"
          value={position}
          onChange={(event) => {
            setPosition(event.target.value);
          }}
        />
        {/* <TextField
          variant="outlined"
          label="[PT] Texto"
          fullWidth
          className="mb-3"
          value={pt}
          onChange={(event) => {
            setPt(event.target.value);
          }}
        /> */}
        <ReactQuill
          theme="snow"
          style={{ width: '100%', marginBottom: '10px' }}
          value={pt}
          onChange={(value) => {
            setPt(value);
          }}
          placeholder="[PT] Texto"
          modules={quillModules}
        />
        {/* <TextField
          variant="outlined"
          label="[EN] Texto"
          fullWidth
          className="mb-3"
          value={en}
          onChange={(event) => {
            setEn(event.target.value);
          }}
        /> */}
         <ReactQuill
          theme="snow"
          style={{ width: '100%', marginBottom: '10px' }}
          value={en}
          onChange={(value) => {
            setEn(value);
          }}
          placeholder="[EN] Texto"
          modules={quillModules}
        />
      </DialogContent>
      <DialogActions>
        <button
          onClick={() => {
            if (!rule) {
              submit(position, pt, en)
            } else {
              submit(rule.id, position, pt, en)
            }
            setPosition(0)
            setPt("");
            setEn("");
            onclose();
          }}
          className="btn btn-info font-weight-bold font-size-sm mr-5 mb-5"
          disabled={pt === "" || en === ""}
        >
          {rule ? "Atualizar" : "Adicionar"}
        </button>
      </DialogActions>
    </Dialog>
  );
};
