import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { deleteRouteTip, addRouteTip, updateRouteTip } from '../../../redux/crud/RouteCrud';
import Fab from '@material-ui/core/Fab';
import { Delete } from '@material-ui/icons';
import { ConfirmDialog } from "../ConfirmDialog";
import { TipDialogForm } from "./TipDialogForm";
import ReactQuill from "react-quill";

export const Tips = ({ route, updateRoute }) => {
  const token = useSelector(state => state.auth.token);

  const [tips, setTips] = useState([]);
  const [touched, setTouched] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteDialogIndex, setDeleteDialogIndex] = useState(-1);
  const [deleteId, setDeleteId] = useState(-1);
  const [addTipDialog, setAddTipDialog] = useState(false);

  const [updatedIndexes, setUpdatedIndexes] = useState([]);

  useEffect(() => {
    setTips(route.tip)
  }, [route])

  const handleDeleteTip = (tipId) => {
    deleteRouteTip(token, route.id, tipId).then(() => {
      updateRoute();
    })
  }

  const handleAddTip = (pt, gb) => {
    addRouteTip(token, route.id, pt, gb).then(() => {
      updateRoute()
    })
  }

  const updateText = (text, language, index) => {
    let texts = [...tips]
    texts[index][language] = text;

    if(!updatedIndexes.includes(index)){
      setUpdatedIndexes([...updatedIndexes, index]);
    }

    setTips(texts);
  }

  const save = () => {
    updatedIndexes.forEach(index => {
      updateRouteTip(token, route.id, tips[index].id, tips[index].pt, tips[index].gb).then(()=>{
        setTouched(false);
        updateRoute();
      })
    })
    setUpdatedIndexes([]);
  }

  const quillModules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['link'],
    ],
  }


  return (

    <div id="images" className="col-lg-12">
      <div className="card card-custom card-stretch gutter-b">
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">Dicas</span>
          </h3>
          <div className="card-toolbar">
            <button onClick={() => setAddTipDialog(true)} className="btn btn-info font-weight-bolder font-size-sm">
              Adicionar Dica
            </button>
          </div>
        </div>
        <div className="card-body pt-0 pb-4 d-flex flex-column">
          {
            tips.map((tip, index) =>
              <div key={`trail-tip-${index}`} className="d-flex align-items-center mb-10">
                <span className="text-dark-75 pl-0 text-left font-weight-bolder mr-3 font-size-lg col-3">
                  Dica #{index + 1}
                </span>
                <div className="d-flex align-items-center flex-column col-8">
                  <ReactQuill
                    
                    theme="snow"
                    style={{ width: '100%', marginBottom: '10px' }}
                    value={tips[index].pt}
                    onChange={(value) => {
                      if (!touched) {
                        setTouched(true);
                      }
                      updateText(value, "pt", index)
                    }}
                    placeholder="PT"
                    modules={quillModules}
                  />
                  {/* <TextField
                    id="outlined-basic"
                    variant="outlined"
                    label="PT"
                    fullWidth
                    className="mb-3"
                    value={tips[index].pt}
                    onChange={(event) => {
                      if (!touched) {
                        setTouched(true);
                      }
                      updateText(event.target.value, "pt", index)
                    }}
                  /> */}
                  <ReactQuill
                    theme="snow"
                    style={{ width: '100%', marginBottom: '10px' }}
                    value={tips[index].gb}
                    onChange={(value) => {
                      if (!touched) {
                        setTouched(true);
                      }
                      updateText(value, "gb", index)
                    }}
                    placeholder="EN"
                    modules={quillModules}
                  />
                  {/* <TextField
                    id="outlined-basic"
                    variant="outlined"
                    label="EN"
                    fullWidth
                    value={tip.gb}
                    onChange={(event) => {
                      if (!touched) {
                        setTouched(true);
                      }
                      updateText(event.target.value, "gb", index)
                    }}
                  /> */}
                </div>
                <div className="col-1">
                  <Fab
                    onClick={() => {
                      setDeleteDialogIndex(index + 1);
                      setDeleteDialog(true);
                      setDeleteId(tip.id);
                    }}
                    size="small"
                    style={{ backgroundColor: "coral", color: "white", marginLeft: "3px" }}
                    aria-label="add"
                  >
                    <Delete />
                  </Fab>
                </div>

              </div>
            )
          }
        </div>
        {
          touched &&
          <div className="d-flex justify-content-end mr-8 mb-8">
            <button
              onClick={save}
              id="add-button"
              className="btn btn-info font-weight-bolder font-size-lg"
            >
              Guardar Alterações
                  </button>
          </div>
        }
      </div>
      <TipDialogForm
        title="Adicionar Dica"
        open={addTipDialog}
        onclose={() => setAddTipDialog(false)}
        submit={(pt, en) => {
          handleAddTip(pt, en);
          setAddTipDialog(false);
        }}
      />
      <ConfirmDialog
        title={`Apagar dica #${deleteDialogIndex}?`}
        open={deleteDialog}
        onclose={() => setDeleteDialog(false)}
        yes={() => {
          setDeleteDialog(false);
          handleDeleteTip(deleteId);
        }}
        no={() => {
          setDeleteDialog(false);
        }}
      />
    </div>
  );
};
