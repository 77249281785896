import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useSelector } from "react-redux";
import { updateInformationInfoSignal } from "../../../redux/crud/InformationCrud";


export const IntroSignalCard = ({ updateInfo }) => {

  const token = useSelector(({ auth }) => auth.token);
  const info = useSelector(({ information }) => information.info);

  const [introSignalPT, setIntroSignalPT] = useState("");
  const [introSignalEN, setIntroSignalEN] = useState("");

  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (info) {
      setIntroSignalPT(info.intro_signal.pt);
      setIntroSignalEN(info.intro_signal.gb);
    }
  }, [info])

  const save = () => {
    updateInformationInfoSignal(token, introSignalPT, introSignalEN).then(() => {
      setTouched(false);
      updateInfo();
    })
  }

  const quillModules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['link'],
    ],
  }

  return (

    <div id="intro" >
      {
        info &&
        <div className="card card-custom card-stretch gutter-b">
          <div className="card-header border-0 py-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label font-weight-bolder text-dark">Introdução Sinalética</span>
            </h3>
          </div>
          <div className="card-body pt-0 pb-4 d-flex flex-column flex-wrap mb-5">
            <span className="text-dark-75 pl-0 text-left font-weight-bolder mr-3 mb-3 font-size-lg col-3">
              Introdução Sinalética PT
          </span>
            <ReactQuill
              theme="snow"
              style={{ width: '100%', marginBottom: '10px' }}
              value={introSignalPT}
              onChange={(value) => {
                if (!touched) {
                  setTouched(true)
                }
                setIntroSignalPT(value);
              }}
              placeholder="PT"
              modules={quillModules}
            />
            <span className="text-dark-75 pl-0 text-left font-weight-bolder mr-3 mb-3 font-size-lg col-3">
              Introdução Sinalética EN
          </span>
            <ReactQuill
              theme="snow"
              style={{ width: '100%' }}
              value={introSignalEN}
              onChange={(value) => {
                if (!touched) {
                  setTouched(true)
                }
                setIntroSignalEN(value);
              }}
              placeholder="EN"
              modules={quillModules}
            />
          </div>
          <div className="d-flex justify-content-end">
            {
              touched &&
              <button
                onClick={save}
                id="add-button"
                className="btn btn-info font-weight-bolder font-size-lg mb-5 mr-10"
              >
                Guardar Alterações
            </button>
            }

          </div>
        </div>

      }

    </div>
  );
};
