export const actionTypes = {
  SetFeedbackTypes: "[SetFeedbackTypes] Action"
};

const initialState = {
  feedbackTypes: [],
};


export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetFeedbackTypes: {
      const { feedbackTypes } = action.payload;
      return { ...state, feedbackTypes };
    }
    default:
      return state;
  }
}

export const actions = {
  setFeedbackTypes: (feedbackTypes) => ({ type: actionTypes.SetFeedbackTypes, payload: { feedbackTypes } })
};

