import React, { useEffect } from "react";
import { useSubheader } from "../../_metronic/layout";
import { useDispatch, useSelector } from "react-redux";
import { getInformation } from "../../redux/crud/InformationCrud";
import { actions as informationActions } from '../../redux/ducks/information.duck';
import { ContactsCard } from "../components/informationBoard/ContactsCard";
import { RulesCard } from "../components/informationBoard/RulesCard";
import { ImageCard } from "../components/informationBoard/ImageCard";
import { IntroCard } from "../components/informationBoard/IntroCard";
import { IntroSignalCard } from "../components/informationBoard/IntroSignalCard";

export const InformationBoard = () => {
  const subheader = useSubheader();
  subheader.setTitle("Informações");

  const dispatch = useDispatch();
  const token = useSelector(({ auth }) => auth.token);

  useEffect(() => {
    getInformationLocal();
  }, []);

  const getInformationLocal = () => {
    getInformation(token).then(({data})=>{
      dispatch(informationActions.setContacts(data.contacts));
      dispatch(informationActions.setRules(data.rules));
      dispatch(informationActions.setImage(data.photo));
      dispatch(informationActions.setInfo(data.info));
    })
  }

  return (<>
    <div className="col-lg-12 mb-8">
      <IntroCard updateInfo={getInformationLocal}/>
    </div>
    <div className="col-lg-12 mb-8">
      <RulesCard updateInfo={getInformationLocal}/>
    </div>
    <div className="col-lg-12 mb-8">
      <ContactsCard updateInfo={getInformationLocal}/>
    </div>
    <div className="col-lg-12 mb-8">
      <IntroSignalCard updateInfo={getInformationLocal}/>
    </div>
    <div className="col-lg-12 mb-8">
      <ImageCard updateInfo={getInformationLocal}/>
    </div>
    
  </>);
};
