import React, { useState, useCallback, useEffect } from "react";
import { DialogTitle, Dialog, DialogContent, DialogActions, TextField } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import { URL } from "../../../redux/config";

export const ContactDialogForm = ({ open, onclose, title, submit, pos, contact }) => {

  const [position, setPosition] = useState(pos);
  const [pt, setPt] = useState("");
  const [en, setEn] = useState("");
  const [numero, setNumero] = useState("");
  const [emergency, setEmergency] = useState(false)
  const [url, setUrl] = useState(null);
  const [b64, setB64] = useState(null);
  const [imageURL, setImageURL] = useState(null);


  const onDrop = useCallback(acceptedFiles => {
    var imgFile = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      setB64(event.target.result);
      setUrl(null);
      setImageURL(null);
    }
    reader.readAsDataURL(imgFile)
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*'
  });

  useEffect(()=>{
    if(contact){
      setPosition(contact.position);
      setPt(contact.name.pt);
      setEn(contact.name.gb);
      setNumero(contact.number);
      setEmergency(contact.emergency);
      if(contact.photo){
        setUrl(contact.photo.url);
        setImageURL(contact.photo.content_base64);
      }
      
    }
  }, [contact])


  return (
    <Dialog
      open={open}
      onClose={onclose}
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <TextField
          variant="outlined"
          label="Posição"
          type="number"
          fullWidth
          className="mb-3"
          value={position}
          onChange={(event) => {
            setPosition(event.target.value);
          }}
        />
        <TextField
          variant="outlined"
          label="[PT] Nome"
          fullWidth
          className="mb-3"
          value={pt}
          onChange={(event) => {
            setPt(event.target.value);
          }}
        />
        <TextField
          variant="outlined"
          label="[EN] Nome"
          fullWidth
          className="mb-3"
          value={en}
          onChange={(event) => {
            setEn(event.target.value);
          }}
        />
        <TextField
          variant="outlined"
          label="Número"
          type="tel"
          fullWidth
          className="mb-3"
          value={numero}
          onChange={(event) => {
            setNumero(event.target.value);
          }}
        />
        <div className="d-flex flex-direction-row mt-1">
          <label className="col-9 col-form-label">Emergência</label>
          <div className="col-3 d-flex justify-content-end">
            <span className="switch switch-primary">
              <label>
                <input type="checkbox" onChange={()=>setEmergency(!emergency)} checked={emergency} name="select" />
                <span></span>
              </label>
            </span>
          </div>
        </div>

        <div className="mt-1 mb-4" style={{ height: 1, backgroundColor: 'rgba(0, 0, 0, 0.25)' }}></div>

        <TextField
          variant="outlined"
          label="Icon URL"
          fullWidth
          className="mb-3"
          value={url ? url : ""}
          onChange={(event) => {
            setUrl(event.target.value);
            setB64(null);
            setImageURL(null);
          }}
        />
        <div className="drag-input" {...getRootProps()}>
          <input className="p0" {...getInputProps()} />
          {
            isDragActive ?
              <p>Arrastar ficheiro para aqui ...</p> :
              <p>Arrastar ficheiros para aqui ou carregar para selecionar</p>
          }
        </div>

        <div id="image-preview" className="d-flex mt-4 justify-content-center">
          {
            url &&
            <img className="route-image" src={url} alt="URL inválido" />
          }
          {
            b64
            &&
            <img className="route-image" src={b64} />
          }
          {
            imageURL
            &&
            <img className="route-image" src={URL + imageURL} />
          }
        </div>
      </DialogContent>
      <DialogActions>
        <button
          onClick={() => {
            if(!contact) {
              submit(position, pt, en, numero, emergency, url, b64)
            } else  {
              submit(contact.id, position, pt, en, numero, emergency, url, b64)
            }
            setPosition(0)
            setPt("");
            setEn("");
            setNumero("")
            setEmergency(false)
            setUrl(null);
            setB64(null);
            onclose();
          }}
          className="btn btn-info font-weight-bold font-size-sm mr-5 mb-5"
          disabled={numero === "" || pt === "" || en === ""}
        >
          {contact ? "Atualizar" : "Adicionar"}
        </button>
      </DialogActions>
    </Dialog>
  );
};
