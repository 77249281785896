import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RuleTable } from "./RuleTable";
import { addRule, updateRule, deleteRule } from "../../../redux/crud/InformationCrud";

export const RulesCard = ({updateInfo}) => {

  const token = useSelector(({ auth }) => auth.token);
  const rules = useSelector(({ information }) => information.rules);

  const addRuleLocal = (position, pt, en) => {
    addRule(token, position, pt, en).then(()=>{
      updateInfo();
    })
  }

  const deleteRuleLocal = (id) => {
    deleteRule(token, id).then(()=>{
      updateInfo();
    })
  }

  const editRuleLocal = (id, position, pt, en) => {
    updateRule(token, id, position, pt, en).then(()=>{
      updateInfo();
    })
  }

  return (
    <RuleTable
      data={rules}
      title={"Lista de Regras"}
      subtitle={"Pode Consultar, Adicionar, Editar e Apagar Regras"}
      addRow={addRuleLocal}
      deleteRow={deleteRuleLocal}
      editRow={editRuleLocal}
    />
  );
};
