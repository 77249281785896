import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null)

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (meta, fieldname) => {
    let result = "form-control form-control-solid h-auto py-5 px-6 ";
    if (meta.touched && meta.error) {
      result += " is-invalid"
    }

    if (meta.touched && !meta.error) {
      result += " is-valid"
    }

    return result;
  }

  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    validate: values => {
      const errors = {};

      if (!values.email) {
        // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
        errors.email = "O email é necessário para fazer Login";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = intl.formatMessage({
          id: "AUTH.VALIDATION.INVALID_FIELD"
        });
      }

      if (!values.password) {
        errors.password = intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        });
      }

      return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        login(values.email, values.password)
          .then(({ data: { token, email, name, id, type, photo } }) => {
            disableLoading();
            if(type === "ADMIN"){
              props.login(token, email, name, id, photo);
            } else {
              setStatus("Utilizador Inválido");
            }            
          })
          .catch((e) => {
            disableLoading();
            setSubmitting(false);
            setStatus("Credenciais Inválidas");
          });
      }, 1000);
    }
  })

  return (
    <div className="login-form login-signin">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        {/* https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage */}
        <h3 className="font-size-h1">Login</h3>
        <p className="text-muted font-weight-bold">Inserir Email e Password</p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}


      <form
        className="form"
        noValidate={true}
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        {status ? (
          <div role="alert" className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{status}</div>
          </div>
        ) : (
            <div>
            </div>
          )}

        <div className="form-group">
          <div>
            <input
              type="email"
              className={`${getInputClasses(formik.getFieldMeta("email"))}`}
              placeholder="Inserir Email"
              label="Inserir Email"
              value={formik.values.email}
              onChange={(x) => formik.setFieldValue("email", x.target.value)}
            />
            {
              formik.getFieldMeta("email").touched &&
              formik.getFieldMeta("email").error &&
              <div className="error invalid-feedback">{formik.getFieldMeta("email").error}</div>
            }
          </div>
        </div>

        <div className="form-group">

          <div>
            <input
              type="password"
              className={`${getInputClasses(formik.getFieldMeta("password"))}`}
              placeholder="Enter Password" 
              value={formik.values.password}
              onChange={(x) => formik.setFieldValue("password", x.target.value)}
            />
            {
              formik.getFieldMeta("password").touched &&
              formik.getFieldMeta("password").error && 
              <div className="error invalid-feedback">{formik.getFieldMeta("password").error}</div>
            }
          </div>

        </div>

        {/* begin::Actions */}
        <div className="form-group d-flex flex-wrap justify-content-end align-items-center">

          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span className={`${loading ? "pr-3" : ""}`}>Sign In</span>
            {loading && (
              <span className="spinner-border text-light"></span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>

      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
