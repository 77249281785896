import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getRouteGeojson, updateRouteGeojson } from '../../../redux/crud/RouteCrud';
import { useParams } from "react-router-dom";
import { TextField } from "@material-ui/core";
import { Map } from "../Map";


export const RouteMap = ({color}) => {
  let { id } = useParams();
  const token = useSelector(state => state.auth.token);

  const [geojson, setGeojson] = useState("");

  const [mapTouched, setMapTouched] = useState(false);

  useEffect(() => {
    getRouteGeojson(token, id).then(({ data }) => {
      setGeojson(JSON.stringify(data.geojson));
    })
  }, []);


  const saveGeojson = () => {
    updateRouteGeojson(token, id, geojson).then(() => {
      setMapTouched(false);
    })
  }

  return (
    <div id="map" className="col-lg-6 col-md-12">
      <div className="card card-custom card-stretch gutter-b">
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">Mapa</span>
          </h3>
        </div>
        <div className="card-body pt-0 pb-3">
          <div id="geojson-map" className="d-flex align-items-center mb-10">
            {
              geojson &&
              <Map
                geojson={JSON.parse(geojson)}
                color={color}
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAmiUjdsA6NjWF0Vz8Saw5TqD8zDqjJwrs&v=3.exp&libraries=geometry,drawing,places"
                loadingElement={<div style={{ height: `100%`, width: `100%`, borderRadius: '5px' }} />}
                containerElement={<div style={{ height: `400px`, width: `100%`, borderRadius: '5px' }} />}
                mapElement={<div style={{ height: `100%`, width: `100%`, borderRadius: '5px' }} />}
              />
            }
          </div>
          <div id="geojson-input" className="d-flex align-items-center mb-10">
            <span className="text-dark-75 pl-0 text-left font-weight-bolder mr-3 font-size-lg col-3">
              Geojson
            </span>
            <div className="d-flex align-items-center flex-column col-9">
              <TextField
                id="geojson-field"
                variant="outlined"
                label="Geojson"
                multiline
                rows={12}
                fullWidth
                className="mb-3"
                value={geojson}
                onChange={(event) => {
                  setMapTouched(true);
                  setGeojson(event.target.value);
                }}
              />
            </div>
          </div>
          {
            mapTouched &&
            <div className="d-flex justify-content-end mt-10">
              <button
                onClick={saveGeojson}
                id="add-button"
                className="btn btn-info font-weight-bolder font-size-lg mb-5"
              >
                Guardar Alterações
                  </button>
            </div>
          }
        </div>
      </div>
    </div>

  )
};
