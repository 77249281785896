/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React from "react";
import { Link } from "react-router-dom";
import { TableImage } from "../TableImage";

export function ReviewTable({ className, routes }) {
  return (
    <div className={`card card-custom ${className}`}>
      {/* Head */}
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">Reviews de Percursos</span>
          <span className="text-muted mt-3 font-weight-bold font-size-sm">Lista com as reviews feitas por percuso</span>
        </h3>
      </div>
      {/* Body */}
      <div className="card-body pt-0 pb-3">
        <div className="tab-content">
          <div className="table-responsive">
            <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
              <thead>
                <tr className="text-left text-uppercase">
                  <th className="pl-7" style={{ minWidth: "250px" }}><span className="text-dark-75">Percurso</span></th>
                  <th style={{ minWidth: "100px" }}>Rating</th>
                  <th style={{ minWidth: "100px" }}>Aprovadas</th>
                  <th style={{ minWidth: "100px" }}>Por aprovar</th>
                  <th style={{ minWidth: "80px" }} />
                </tr>
              </thead>
              <tbody>
                {
                  routes.map((route) =>
                    <tr key={route.id}>
                      <td className="pl-0 py-8">
                        <div className="d-flex align-items-center">
                          <div className="symbol symbol-50 symbol-light mr-4">
                            <span className="symbol-label">
                              <TableImage
                                photo={route.photos[0]}
                              />
                            </span>
                          </div>
                          <div>
                            <Link
                              to={`/feedback/${route.id}`}
                              className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                            >
                              {route.name['pt']}
                            </Link>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                          {route.review_count > 0 ? (route.review_sum / route.review_count) : "Sem dados"}
                        </span>
                      </td>
                      <td>
                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                          {route.review_count}
                        </span>
                      </td>
                      <td>
                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                          {route.pending_approval}
                        </span>
                      </td>
                      <td className="pr-0 text-right">
                        <Link
                          to={`/reviews/${route.id}`}
                          className="btn btn-light-success font-weight-bolder font-size-sm"
                        >
                          Ver Reviews
                        </Link>
                      </td>
                    </tr>
                  )
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
