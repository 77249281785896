import React from "react";
import { useSubheader } from "../../_metronic/layout";
import { AttributeCard } from "../components/attributeBoard/AttributeCard";

export const AttributeBoard = () => {
  const subheader = useSubheader();
  subheader.setTitle("Atributos");

  return (<>
    <div className="col-lg-12 mb-8">
      <AttributeCard/>
    </div>
  </>);
};
