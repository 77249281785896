import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { InputLabel, Select, Input, Chip, MenuItem } from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import { getTypes } from "../../../redux/crud/TypeCrud";
import { actions } from '../../../redux/ducks/types.duck';

export const TypePicker = ({ typeFilter, selectTypes }) => {
  const dispatch = useDispatch();
  const token = useSelector(state => state.auth.token);
  const types = useSelector(state => state.types.types);

  const [keys, setKeys] = useState([]);

  const handleChange = (event) => {
    let nameKeys = event.target.value;
    setKeys(nameKeys);

    let selectedValues = types.filter((value) =>
      nameKeys.includes(value.name.pt)
    );

    selectTypes(selectedValues);
  };

  const getCategoriesLocal = () => {
    getTypes(token).then(({ data }) => {
      dispatch(actions.setTypes(data));
    })
  }

  useEffect(() => {
    if (types.length === 0) {
      getCategoriesLocal();
    }
    let keysTemp = []
    typeFilter.forEach((c) => {
      keysTemp.push(c.name.pt)
    })
    setKeys(keysTemp);
  }, []);


  return (
    <div id="category-select" className="d-flex align-items-center mb-10 w-100">
      <span className="text-dark-75 pl-0 text-left font-weight-bolder mr-3 font-size-lg col-3">
        Tipo
      </span>
      <div className="d-flex align-items-center flex-column col-9">
        <FormControl variant="outlined" className="w-100">
          <InputLabel id="demo-mutiple-chip-label">Escolher Tipos</InputLabel>
          <Select
            labelId="demo-mutiple-chip-label"
            id="demo-mutiple-chip"
            multiple
            fullWidth
            variant="outlined"
            value={keys}
            onChange={handleChange}
            input={<Input id="select-multiple-chip" className="p-0" />}
            renderValue={(selected) => (
              <div className="d-flex flex-wrap">
                {selected.map((value) => (
                  <Chip key={value} label={value} className="m-0" />
                ))}
              </div>
            )}
          >
            {types.map((type) => (
              <MenuItem key={type.key} value={type.name.pt}>
                {type.name.pt}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>



  );
};
