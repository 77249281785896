export const actionTypes = {
  SetFeedback: "[SetFeedback] Action"
};

const initialState = {
  feedback: [],
};


export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetFeedback: {
      const { feedback } = action.payload;
      return { ...state, feedback };
    }
    default:
      return state;
  }
}

export const actions = {
  setFeedback: (feedback) => ({ type: actionTypes.SetFeedback, payload: { feedback } })
};

