/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React from "react";
import { TableImage } from "./TableImage";
import { Link } from "react-router-dom";

export function RouteTable({ className, routes }) {
  return (
    <div className={`card card-custom ${className}`}>
      {/* Head */}
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">Percursos</span>
          <span className="text-muted mt-3 font-weight-bold font-size-sm">Lista com todas os percursos</span>
        </h3>
        <div className="card-toolbar">
          <Link to="/percursos/novo" className="btn btn-info font-weight-bolder font-size-sm">Adicionar Percurso</Link>
        </div>
      </div>
      {/* Body */}
      <div className="card-body pt-0 pb-3">
        <div className="tab-content">
          <div className="table-responsive">
            <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
              <thead>
                <tr className="text-left text-uppercase">
                  <th className="pl-7" style={{ minWidth: "250px" }}><span className="text-dark-75">Percurso</span></th>
                  <th style={{ minWidth: "100px" }}>posição</th>
                  <th style={{ minWidth: "100px" }}>comprimento</th>
                  <th style={{ minWidth: "100px" }}>reviews</th>
                  <th style={{ minWidth: "130px" }}>rating</th>
                  <th style={{ minWidth: "80px" }} />
                </tr>
              </thead>
              <tbody>
                {
                  routes.map((route) =>
                    <tr key={route.id}>
                      <td className="pl-0 py-8">
                        <div className="d-flex align-items-center">
                          <div className="symbol symbol-50 symbol-light mr-4">
                            <span className="symbol-label">
                              <TableImage
                                photo={route.photos[0]}
                              />
                              {/* <span className="svg-icon h-75 align-self-end">
                                <SVG src={toAbsoluteUrl("/media/svg/avatars/001-boy.svg")} />
                              </span> */}
                            </span>
                          </div>
                          <div>
                            <Link  to={`/percursos/${route.id}`} className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
                              {route.name['pt']}
                            </Link>
                      
                          </div>
                        </div>
                      </td>
                      <td>
                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                          {route.position}
                        </span>
                      </td>
                      <td>
                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                          {route.length.metric}m
                        </span>
                      </td>
                      <td>
                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                          {route.review_count}
                        </span>
                      </td>
                      
                      <td>
                        
                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                          { route.review_count > 0 ?
                            (route.review_sum/route.review_count).toFixed(2)
                            :
                            "Sem dados"
                          }
                        </span>
                      </td>
                      <td className="pr-0 text-right">
                        <Link to={`/percursos/${route.id}`} className="btn btn-light-success font-weight-bolder font-size-sm">Ver Rota</Link>
                      </td>
                    </tr>
                  )
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
