import React, { useState } from "react";
import { ContactDialogForm } from "./ContactDialogForm";
import { URL } from "../../../redux/config";
import { ConfirmDialog } from "../ConfirmDialog";

export const Table = ({ columns, data }) => {

  return (
    <table className="table">
      <thead>
        <tr>
          {
            columns && columns.map((column, index) => <th key={`label-${index}`} scope="col">{column.label}</th>)
          }
        </tr>
      </thead>
      <tbody>
        {data && data.map((row, index) => {
          return <tr key={`row-${index}`}>
            {
              columns && columns.map((column, index2) => {
                if (column.template) {
                  return <td key={`row2-${index2}`}>{column.template(row)}</td>
                  } else {
                  if (column.primary) {
                    return <th key={`row2-${index2}`} scope="row">{row[column.name]}</th>
                  } else {
                    return <td key={`row2-${index2}`}>{row[column.name]}</td>
                  }
                }
              })
            }
          </tr>
        })}
      </tbody>
    </table>
  )
}

export const ContactTable = ({ data, title, subtitle, addRow, deleteRow, editRow }) => {

  const [addDialog, setAddDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteDialogInfo, setDeleteDialogInfo] = useState(null);
  const [editDialog, setEditDialog] = useState(false);
  const [editDialogInfo, setEditDialogInfo] = useState(null);

  const openRemoveDialog = (id) => {
    setDeleteDialogInfo({
      title: `Tem a certeza que quer apagar "${id}"?`,
      id: id
    })
    setDeleteDialog(true);
  }

  const openEditDialog = (contact) => {
    setEditDialog(true);
    setEditDialogInfo({
      contact
    })
  }

  const columns = [
    {
      name: "id",
      label: "#",
      primary: true
    },
    {
      name: "img",
      label: "Icon",
      template: row => {
        return (
          <>
            {
              row.photo &&
              <img
                style={{ width: 24, height: 24, objectFit: "cover" }}
                src={row.photo.url ? row.photo.url : URL + row.photo.content_base64}
              />
            }

          </>
        )
      }
    },
    {
      name: "position",
      label: "Posição"
    },
    {
      name: "name",
      label: "[PT] Nome",
      template: row => row.name.pt
    },
    {
      name: "name",
      label: "[EN] Nome",
      template: row => row.name.gb
    },
    {
      name: "number",
      label: "Número",
    },
    {
      name: "emergency",
      label: "Emergência",
      template: row => row.emergency ? "Sim" : "Não"
    },
    {
      name: "actions",
      label: "Ações",
      template: row => {
        return (
          <>
            <button onClick={() => openEditDialog(row)} style={{ fontSize: 10 }} className="btn btn-light btn-sm mr-2">
              <i style={{ fontSize: 10 }} className="flaticon2-edit"></i>Editar
            </button>
            <button onClick={() => openRemoveDialog(row.id)} style={{ fontSize: 10 }} className="btn btn-light btn-sm">
              <i style={{ fontSize: 10 }} className="flaticon2-delete"></i>Remover
            </button>
          </>
        )

      }
    }
  ]

  return (
    <div className="card card-custom">
      <div className="card-header flex-wrap border-0 pt-6 pb-0">
        <div className="card-title">
          <h3 className="card-label">{title}
            <span className="text-muted pt-2 font-size-sm d-block">{subtitle}</span>
          </h3>
        </div>
        <div className="card-toolbar">
          <button onClick={() => setAddDialog(true)} className="btn btn-info font-weight-bolder font-size-sm">
            Adicionar
          </button>
        </div>
      </div>
      <div className="card-body">
        <Table columns={columns} data={data} />
      </div>
      <ContactDialogForm
        open={addDialog}
        onclose={() => setAddDialog(false)}
        title="Adicionar novo Contacto"
        pos={data.length + 1}
        submit={addRow}
      />
      <ContactDialogForm
        open={editDialog}
        onclose={() => setEditDialog(false)}
        title={`Editar Contacto ${editDialogInfo ? editDialogInfo.contact.id : ""}`}
        pos={data.length + 1}
        submit={editRow}
        contact={editDialogInfo ? editDialogInfo.contact : null}
      />
      <ConfirmDialog
        open={deleteDialog}
        title={deleteDialogInfo ? deleteDialogInfo.title : ""}
        onclose={() => {
          setDeleteDialog(false);
          setDeleteDialogInfo(null);
        }}
        no={() => setDeleteDialog(false)}
        yes={() => {
          deleteRow(deleteDialogInfo.id)
          setDeleteDialog(false);
        }}

      />
    </div>
  )


}
