import { URL } from '../config';
import Axios from 'axios';

export function getUsers(token, page=1, size=10) {
  const params = {
    page,
    size
  }
  return Axios.get(`${URL}/users`, {
    params,
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }
  );
}
