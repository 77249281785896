import axios from "axios";
import { URL } from '../../../../redux/config';

export const LOGIN_URL = "/login";
export const LOGOUT_URL = "/logout";

export function login(email, password) {
  return axios.post(URL + LOGIN_URL, { email, password });
}


export function logout(token) {

  return axios({
    method: 'post',
    url: `${URL}${LOGOUT_URL}`,
    headers: {
      "Authorization": `Bearer ${token}`
    }
  })
}