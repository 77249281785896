import React, { useState, useCallback, useEffect } from "react";
import { DialogTitle, Dialog, DialogContent, DialogActions, TextField, Switch } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import { URL } from "../../../redux/config";

export const EditAttributeDialogForm = ({ open, onclose, title, submit, attribute }) => {

  const [key, setKey] = useState("");
  const [pt, setPt] = useState("");
  const [en, setEn] = useState("");
  const [url, setUrl] = useState(null);
  const [b64, setB64] = useState(null);
  const [image, setImage] = useState(null);
  const [inList, setInList] = useState(false);

  const [touched, setTouched] = useState(false);


  const onDrop = useCallback(acceptedFiles => {
    var imgFile = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      setB64(event.target.result);
      setUrl(null);
      setTouched(true);
    }
    reader.readAsDataURL(imgFile)
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*'
  });

  useEffect(()=>{
    if(attribute) {
      setKey(attribute.key);
      setPt(attribute.name.pt);
      setEn(attribute.name.gb);
      setInList(attribute.in_list);
      setImage(attribute.photo.url ? attribute.photo.url : URL + attribute.photo.content_base64)
    }
   
  }, [attribute])


  return (
    <Dialog
      open={open}
      onClose={onclose}
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <TextField
          id="outlined-basic"
          variant="outlined"
          label="Chave"
          fullWidth
          className="mb-3"
          value={key}
          onChange={(event) => {
            setTouched(true);
            setKey(event.target.value);
          }}
        />
        <TextField
          id="outlined-basic"
          variant="outlined"
          label="PT"
          fullWidth
          className="mb-3"
          value={pt}
          onChange={(event) => {
            setTouched(true);
            setPt(event.target.value);
          }}
        />
        <TextField
          id="outlined-basic"
          variant="outlined"
          label="EN"
          fullWidth
          className="mb-3"
          value={en}
          onChange={(event) => {
            setTouched(true);
            setEn(event.target.value);
          }}
        />
        <div className="mb-3">
          <span style={{marginRight: '20px'}}>Listagem:</span>
         
          <Switch
            checked={inList}
            onChange={()=>{
              setTouched(true);
              setInList(!inList);
            }}
           
          />
        </div>
        <TextField
          id="outlined-basic"
          variant="outlined"
          label="Icon URL"
          fullWidth
          className="mb-3"
          value={url ? url : ""}
          onChange={(event) => {
            setTouched(true);
            setUrl(event.target.value);
            setB64(null);
          }}
        />
        <div className="drag-input" {...getRootProps()}>
          <input className="p0" {...getInputProps()} />
          {
            isDragActive ?
              <p>Arrastar ficheiro para aqui ...</p> :
              <p>Arrastar ficheiros para aqui ou carregar para selecionar</p>
          }
        </div>

        <div id="image-preview" className="d-flex mt-4 justify-content-center">
          {
            url &&
            <img className="route-image" src={url} alt="URL inválido" />
          }
          {
            b64
            &&
            <img className="route-image" src={b64} />
          }
            {
              !(url || b64) &&image
              &&
              <img className="route-image" src={image} />
            }
        </div>
      </DialogContent>
      <DialogActions>
        <button
          onClick={() => {
            submit(attribute.id, key, pt, en, url, b64, inList);
            setKey("");
            setPt("");
            setEn("");
            setUrl(null);
            setB64(null);
            setInList(false);
            setTouched(false)
          }}
          className="btn btn-info font-weight-bold font-size-sm mr-5 mb-5"
          disabled={!touched}
        >
          Guardar
        </button>
      </DialogActions>
    </Dialog>
  );
};
