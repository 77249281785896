export const actionTypes = {
  SetRoutes: "[SetRoutes] Action"
};

const initialRoutesState = {
  routes: undefined,
};


export const reducer = (state = initialRoutesState, action) => {
  switch (action.type) {
    case actionTypes.SetRoutes: {
      const { routes } = action.payload;
      return { ...state, routes };
    }
    default:
      return state;
  }
}

export const actions = {
  setRoutes: (routes) => ({ type: actionTypes.SetRoutes, payload: { routes } })
};

