import React, { useState } from "react";
import { ConfirmDialog } from "../ConfirmDialog";
import { TopographyInputDialogForm } from "./TopographyInputDialogForm";


export const Table = ({ columns, data }) => {

  return (
    <table className="table">
      <thead>
        <tr>
          {
            columns && columns.map((column, index) => <th key={`label-${index}`} scope="col">{column.label}</th>)
          }
        </tr>
      </thead>
      <tbody>
        {data && data.map((row, index) => {
          return <tr key={`row-${index}`}>
            {
              columns && columns.map((column, index2) => {
                if (column.template) {
                  return <td key={`row2-${index2}`}>{column.template(row)}</td>
                } else {
                  if (column.primary) {
                    return <th key={`row2-${index2}`} scope="row">{row[column.name]}</th>
                  } else {
                    return <td key={`row2-${index2}`}>{row[column.name]}</td>
                  }
                }
              })
            }
          </tr>
        })}
      </tbody>
    </table>
  )
}

export const TopographyTable = ({ data, title, subtitle, addRow, deleteRow, addDialogTitle }) => {

  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteDialogInfo, setDeleteDialogInfo] = useState(null);

  const [addDialog, setAddDialog] = useState(false);

  

  const openRemoveDialog = (id, key) => {
    setDeleteDialogInfo({
      title: `Tem a certeza que quer apagar "${key}"?`,
      id: id
    })
    setDeleteDialog(true);
    
  }

  const columns = [
    {
      name: "id",
      label: "#",
      primary: true
    },
    {
      name: "key",
      label: "Chave"
    },
    {
      name: "name",
      label: "[PT] Nome Metros",
      template: row => row.name.pt
    },
    {
      name: "name",
      label: "[EN] Nome Metros",
      template: row => row.name.gb
    },
    {
      name: "name",
      label: "[PT] Nome Ft",
      template: row => row.name_feet.pt
    },
    {
      name: "name",
      label: "[EN] Nome Ft",
      template: row => row.name_feet.gb
    },
    {
      name: "actions",
      label: "Ações",
      template: row => {
        return (
          <>
            <button onClick={()=>openRemoveDialog(row.id, row.key)} style={{ fontSize: 10 }} className="btn btn-light btn-sm">
              <i style={{ fontSize: 10 }} className="flaticon2-delete"></i>Apagar
            </button>
          </>
        )

      }
    }
  ]

  return (
    <div className="card card-custom">
      <div className="card-header flex-wrap border-0 pt-6 pb-0">
        <div className="card-title">
          <h3 className="card-label">{title}
            <span className="text-muted pt-2 font-size-sm d-block">{subtitle}</span>
          </h3>
        </div>
        <div className="card-toolbar">
          <button onClick={()=>setAddDialog(true)} className="btn btn-info font-weight-bolder font-size-sm">
            Adicionar
          </button>
        </div>
      </div>
      <div className="card-body">
        <Table columns={columns} data={data} />
        <ConfirmDialog
          open={deleteDialog}
          title={deleteDialogInfo ? deleteDialogInfo.title : ""}
          onclose={()=> {
            setDeleteDialog(false);
            setDeleteDialogInfo(null);
          }}
          no={()=>setDeleteDialog(false)}
          yes={()=>{
            deleteRow(deleteDialogInfo.id)
            setDeleteDialog(false);
          }}
        />
        <TopographyInputDialogForm
          open={addDialog}
          onclose={()=>setAddDialog(false)}
          title={addDialogTitle}
          submit={(key, pt, en, ptFeet, enFeet)=>{
            addRow(key, pt, en, ptFeet, enFeet);
            setAddDialog(false);
          }}
        />
      </div>
    </div>
  )


}
