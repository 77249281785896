import React, { useState } from "react";
import { DialogTitle, Dialog, DialogContent, DialogActions, TextField } from "@material-ui/core";

export const TopographyInputDialogForm = ({ open, onclose, title, submit }) => {

  const [key, setKey] = useState("");
  const [pt, setPt] = useState("");
  const [en, setEn] = useState("");
  const [ptFeet, setPtFeet] = useState("");
  const [enFeet, setEnFeet] = useState("");


  return (
    <Dialog
      open={open}
      onClose={onclose}
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <TextField
          id="outlined-basic"
          variant="outlined"
          label="Key"
          fullWidth
          className="mb-3"
          value={key}
          onChange={(event) => {
            setKey(event.target.value);
          }}
        />
        <TextField
          id="outlined-basic"
          variant="outlined"
          label="[PT] Nome Metros"
          fullWidth
          className="mb-3"
          value={pt}
          onChange={(event) => {
            setPt(event.target.value);
          }}
        />
        <TextField
          id="outlined-basic"
          variant="outlined"
          label="[EN] Nome Metros"
          fullWidth
          className="mb-3"
          value={en}
          onChange={(event) => {
            setEn(event.target.value);
          }}
        />
        <TextField
          id="outlined-basic"
          variant="outlined"
          label="[PT] Nome Feet"
          fullWidth
          className="mb-3"
          value={ptFeet}
          onChange={(event) => {
            setPtFeet(event.target.value);
          }}
        />
        <TextField
          id="outlined-basic"
          variant="outlined"
          label="[EN] Nome Feet"
          fullWidth
          value={enFeet}
          onChange={(event) => {
            setEnFeet(event.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <button
          onClick={()=>{
            submit(key, pt, en, ptFeet, enFeet);
            setKey("");
            setPt("");
            setEn("");
            setPtFeet("");
            setEnFeet("");
          }}
          className="btn btn-info font-weight-bold font-size-sm mr-5 mb-5"
          disabled={key === "" || pt === "" || en === ""}
        >
          Adicionar
        </button>
      </DialogActions>
    </Dialog>
  );
};
