import React from "react";
import { useSubheader } from "../../_metronic/layout";
import { SponsorCard } from "../components/sponsorBoard/SponsorCard";

export const SponsorBoard = () => {
  const subheader = useSubheader();
  subheader.setTitle("Patrocinadores");

  return (<>
    <div className="col-lg-12 mb-8">
      <SponsorCard/>
    </div>
  </>);
};
