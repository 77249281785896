import React from "react";
import { useSelector } from "react-redux";
import { ContactTable } from "./ContactTable";
import { addContact, deleteContact, updateContact } from "../../../redux/crud/InformationCrud";

export const ContactsCard = ({ updateInfo }) => {
  const token = useSelector(({ auth }) => auth.token);

  const contacts = useSelector(({ information }) => information.contacts);

  const addContactLocal = (position, pt, en, numero, emergency, url, b64) => {
    addContact(token, position, numero, emergency, pt, en, url, b64).then(() => {
      updateInfo();
    })
  }

  const deleteContactLocal = (id) => {
    deleteContact(token, id).then(() => {
      updateInfo();
    })
  }

  const editContactLocal = (id, position, pt, en, numero, emergency, url, b64) => {
    updateContact(token, id, position, numero, emergency, pt, en, url, b64).then(() => {
      updateInfo();
    })
  }

  return (
    <ContactTable
      data={contacts}
      title={"Lista de Contactos"}
      subtitle={"Pode Consultar, Adicionar, Editar e Apagar Contactos"}
      addRow={addContactLocal}
      deleteRow={deleteContactLocal}
      editRow={editContactLocal}
    />
  );
};
