import React, { useEffect } from "react";
import { getCategories, deleteCategory, addCategory } from "../../../redux/crud/CategoryCrud";
import { useSelector, useDispatch } from "react-redux";
import { ContentTable } from "./ContentTable";
import { actions } from '../../../redux/ducks/categories.duck';

export const CategoryCard = () => {
  const dispatch = useDispatch();
  const token = useSelector(state => state.auth.token);
  const categories = useSelector(state => state.categories.categories);

  const getCategoriesLocal = () => {
    getCategories(token).then(({ data }) => {
      dispatch(actions.setCategories(data));
    })
  }

  const deleteCategoryLocal = (id) => {
    deleteCategory(token, id).then(() => {
      getCategoriesLocal();
    })
  }

  const addCategoryLocal = (key, pt, en) => {
    addCategory(token, key, pt, en).then(() => {
      getCategoriesLocal();
    });
  }

  useEffect(() => {
    getCategoriesLocal();
  }, [])


  return (
    <ContentTable 
      data={categories}
      title={"Lista de Localizações"}
      subtitle={"Pode Consultar, Adicionar e Apagar Localizações"}
      addRow={addCategoryLocal}
      deleteRow={deleteCategoryLocal}
      addDialogTitle={"Adicionar Localização"}
    />
   );
};
