import React, { useEffect } from "react";
import { getTypes, addType, deleteType } from "../../../redux/crud/TypeCrud";
import { useSelector, useDispatch } from "react-redux";
import { ContentTable } from "./ContentTable";
import { actions } from '../../../redux/ducks/types.duck';

export const TypeCard = () => {
  const dispatch = useDispatch();
  const token = useSelector(state => state.auth.token);
  const types = useSelector(state => state.types.types);
  

  const getTypesLocal = () => {
    getTypes(token).then(({ data }) => {
      dispatch(actions.setTypes(data));
    })
  }

  const deleteTypeLocal = (id) => {
    deleteType(token, id).then(() => {
      getTypesLocal();
    })
  }

  const addTypeLocal = (key, pt, en) => {
    addType(token, key, pt, en).then(() => {
      getTypesLocal();
    });
  }

  useEffect(() => {
    getTypesLocal();
  }, [])


  return (
    <ContentTable 
      data={types}
      title={"Lista de Tipos"}
      subtitle={"Pode Consultar, Adicionar e Apagar Tipos"}
      addRow={addTypeLocal}
      deleteRow={deleteTypeLocal}
      addDialogTitle={"Adicionar Tipo"}
    />
   );
};
