import React from "react";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import "../../../_metronic/_assets/sass/pages/error/error-3.scss";

export function ErrorPage() {
  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="error error-3 d-flex flex-row-fluid bgi-size-cover bgi-position-center"
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/media/error/bg3.jpg")})`
        }}
      >
        <div className="px-10 px-md-30 py-10 py-md-0 d-flex flex-column justify-content-md-center">
          <h1 className="error-title text-stroke text-transparent">404</h1>
          <p className="display-4 font-weight-boldest text-white mb-12">
            Oops! Caminho errado.
          </p>
          <p className="font-size-h1 font-weight-boldest text-dark-75">
            É melhor voltar atrás, nunca se sabe quais os<br/>
            perigos que nos esperam fora do caminho.
          </p>
          <p className="font-size-h4 line-height-md">
            O URL inserido pode estar errado
            <br />
            ou a página pode já não existir.
          </p>
        </div>
      </div>
    </div>
  );
}
