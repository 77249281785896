export const actionTypes = {
  SetCategories: "[SetCategories] Action"
};

const initialState = {
  categories: [],
};


export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetCategories: {
      const { categories } = action.payload;
      return { ...state, categories };
    }
    default:
      return state;
  }
}

export const actions = {
  setCategories: (categories) => ({ type: actionTypes.SetCategories, payload: { categories } })
};

