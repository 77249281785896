export const actionTypes = {
  SetAttributes: "[SetAttributes] Action"
};

const initialState = {
  attributes: [],
};


export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetAttributes: {
      const { attributes } = action.payload;
      return { ...state, attributes };
    }
    default:
      return state;
  }
}

export const actions = {
  setAttributes: (attributes) => ({ type: actionTypes.SetAttributes, payload: { attributes } })
};

