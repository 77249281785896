import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import * as routes from './ducks/routes.duck';
import * as categories from './ducks/categories.duck';
import * as types from './ducks/types.duck';
import * as topographies from './ducks/topographies.duck';
import * as accesses from './ducks/access.duck';
import * as feedbackTypes from './ducks/feedbackType.duck';
import * as attributes from './ducks/attribute.duck';
import * as feedback from './ducks/feedback.duck';
import * as reviews from './ducks/reviews.duck';
import * as information from './ducks/information.duck';
import * as sponsors from './ducks/sponsor.duck';
import * as users from './ducks/users.duck';
import * as terms from './ducks/termsAndConditions.duck';

export const rootReducer = combineReducers({
  auth: auth.reducer,
  routes: routes.reducer,
  categories: categories.reducer,
  types: types.reducer,
  topographies: topographies.reducer,
  accesses: accesses.reducer,
  feedbackTypes: feedbackTypes.reducer,
  attributes: attributes.reducer,
  feedback: feedback.reducer,
  reviews: reviews.reducer,
  information: information.reducer,
  sponsors: sponsors.reducer,
  users: users.reducer,
  terms: terms.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
