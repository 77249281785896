import React, { useEffect } from "react";
import { getFeedbackTypes, deleteFeedbackType, addFeedbackType } from "../../../redux/crud/FeedbackTypeCrud";
import { useSelector, useDispatch } from "react-redux";
import { ContentTable } from "../contentBoard/ContentTable";
import { actions } from '../../../redux/ducks/feedbackType.duck';

export const FeedbackTypeCard = () => {
  const dispatch = useDispatch();
  const token = useSelector(state => state.auth.token);
  const feedbackTypes = useSelector(state => state.feedbackTypes.feedbackTypes);

  const getFeedbackTypesLocal = () => {
    getFeedbackTypes(token).then(({ data }) => {
      dispatch(actions.setFeedbackTypes(data));
    })
  }

  const deleteFeedbackTypeLocal = (id) => {
    deleteFeedbackType(token, id).then(() => {
      getFeedbackTypesLocal();
    })
  }

  const addFeedbackTypeLocal = (key, pt, en) => {
    addFeedbackType(token, key, pt, en).then(() => {
      getFeedbackTypesLocal();
    });
  }

  useEffect(() => {
    getFeedbackTypesLocal();
  }, [])


  return (
    <ContentTable 
      data={feedbackTypes}
      title={"Lista de Tipos de Feedback"}
      subtitle={"Pode Consultar, Adicionar e Apagar Tipos de Feedback"}
      addRow={addFeedbackTypeLocal}
      deleteRow={deleteFeedbackTypeLocal}
      addDialogTitle={"Adicionar Tipo de Feedback"}
    />
   );
};
