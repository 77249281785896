import React, { useState } from "react";
import { ConfirmDialog } from "../ConfirmDialog";
import { SponsorDialogForm } from "./SponsorDialogForm";
import { URL } from "../../../redux/config";

export const Table = ({ columns, data }) => {
  return (
    <table className="table">
      <thead>
        <tr>
          {columns &&
            columns.map((column, index) => (
              <th key={`label-${index}`} scope="col">
                {column.label}
              </th>
            ))}
        </tr>
      </thead>
      <tbody>
        {data &&
          data.map((row, index) => {
            return (
              <tr key={`row-${index}`}>
                {columns &&
                  columns.map((column, index2) => {
                    if (column.template) {
                      return (
                        <td key={`row2-${index2}`}>{column.template(row)}</td>
                      );
                    } else {
                      if (column.primary) {
                        return (
                          <th key={`row2-${index2}`} scope="row">
                            {row[column.name]}
                          </th>
                        );
                      } else {
                        return (
                          <td key={`row2-${index2}`}>{row[column.name]}</td>
                        );
                      }
                    }
                  })}
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export const SponsorTable = ({
  data,
  title,
  subtitle,
  addRow,
  deleteRow,
  addDialogTitle,
  editRow,
}) => {
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteDialogInfo, setDeleteDialogInfo] = useState(null);

  const [addDialog, setAddDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [editDialogInfo, setEditDialogInfo] = useState(null);

  const openRemoveDialog = (id, key) => {
    setDeleteDialogInfo({
      title: `Tem a certeza que quer apagar "${key}"?`,
      id: id,
    });
    setDeleteDialog(true);
  };

  const openEditDialog = (sponsor) => {
    setEditDialog(true);
    setEditDialogInfo({
      sponsor,
    });
  };

  function htmlMarkup(text) {
    return { __html: text };
  }

  const columns = [
    {
      name: "id",
      label: "#",
      primary: true,
    },
    {
      name: "name",
      label: "Nome",
    },
    {
      name: "img",
      label: "Icon",
      template: (row) => {
        if(row.have_photo){
          return (
              <>
                {row.photos[0] && (
                    <img
                        style={{ width: 24, height: 24, objectFit: "fill" }}
                        src={
                          row.photos[0].url
                              ? row.photos[0].url
                              : URL + row.photos[0].content_base64
                        }
                    />
                )}
              </>
          );
        }else{
          return <>N/A</>
        }

      },
    },
    {
      name: "name",
      label: "[PT] Descrição",
      template: (row) => (
        <div dangerouslySetInnerHTML={htmlMarkup(row.description.pt)}></div>
      )
    },

    {
      name: "name",
      label: "[EN] Descrição",
      template: (row) => (
        <div dangerouslySetInnerHTML={htmlMarkup(row.description.gb)}></div>
      ),
    },
    {
      name: "actions",
      label: "Ações",
      template: (row) => {
        return (
          <>
            <button
              onClick={() => openEditDialog(row)}
              style={{ fontSize: 10 }}
              className="btn btn-light btn-sm mr-2 mb-2"
            >
              <i style={{ fontSize: 10 }} className="flaticon2-edit"></i>Editar
            </button>
            <button
              onClick={() => openRemoveDialog(row.id, row.name)}
              style={{ fontSize: 10 }}
              className="btn btn-light btn-sm"
            >
              <i style={{ fontSize: 10 }} className="flaticon2-delete"></i>
              Remove
            </button>
          </>
        );
      },
    },
  ];

  return (
    <div className="card card-custom">
      <div className="card-header flex-wrap border-0 pt-6 pb-0">
        <div className="card-title">
          <h3 className="card-label">
            {title}
            <span className="text-muted pt-2 font-size-sm d-block">
              {subtitle}
            </span>
          </h3>
        </div>
        <div className="card-toolbar">
          <button
            onClick={() => setAddDialog(true)}
            className="btn btn-info font-weight-bolder font-size-sm"
          >
            Adicionar
          </button>
        </div>
      </div>
      <div className="card-body">
        <Table columns={columns} data={data} />
        <ConfirmDialog
          open={deleteDialog}
          title={deleteDialogInfo ? deleteDialogInfo.title : ""}
          onclose={() => {
            setDeleteDialog(false);
            setDeleteDialogInfo(null);
          }}
          no={() => setDeleteDialog(false)}
          yes={() => {
            deleteRow(deleteDialogInfo.id);
            setDeleteDialog(false);
          }}
        />
        <SponsorDialogForm
          open={addDialog}
          onclose={() => setAddDialog(false)}
          title={addDialogTitle}
          submit={(key, pt, en, url, b64, havePhoto) => {
            addRow(key, pt, en, url, b64, havePhoto);
            setAddDialog(false);
          }}
          button={"Adicionar"}
        />
        <SponsorDialogForm
          open={editDialog}
          onclose={() => setEditDialog(false)}
          title={`Editar Sponsor ${
            editDialogInfo ? editDialogInfo.sponsor.id : ""
          }`}
          submit={(key, pt, en, url, b64, havePhoto) => {
            editRow(editDialogInfo.sponsor.id, key, pt, en, url, b64, havePhoto);
            setEditDialog(false);
          }}
          sponsor={editDialogInfo ? editDialogInfo.sponsor : null}
          button={"Guardar"}
        />
      </div>
    </div>
  );
};
