import React, { useState } from "react";
import { DialogTitle, Dialog, DialogContent, DialogActions, TextField } from "@material-ui/core";
import ReactQuill from "react-quill";

export const TipDialogForm = ({ open, onclose, title, submit }) => {

  const [pt, setPt] = useState("");
  const [en, setEn] = useState("");

  const quillModules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['link'],
    ],
  }

  return (
    <Dialog
      open={open}
      onClose={onclose}
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <ReactQuill
          theme="snow"
          style={{ width: '100%', marginBottom: '10px' }}
          value={pt}
          onChange={(value) => {
            setPt(value);
          }}
          placeholder="PT"
          modules={quillModules}
        />
        {/* <TextField
          id="outlined-basic"
          variant="outlined"
          label="PT"
          fullWidth
          className="mb-3"
          value={pt}
          onChange={(event) => {
            setPt(event.target.value);
          }}
        /> */}
        <ReactQuill
          theme="snow"
          style={{ width: '100%', marginBottom: '10px' }}
          value={en}
          onChange={(value) => {
            setEn(value);
          }}
          placeholder="EN"
          modules={quillModules}
        />
        {/* <TextField
          id="outlined-basic"
          variant="outlined"
          label="EN"
          fullWidth
          value={en}
          onChange={(event) => {
            setEn(event.target.value);
          }}
        /> */}
      </DialogContent>
      <DialogActions>
        <button
          onClick={()=>{
            submit(pt, en);
            setPt("");
            setEn("");
          }}
          className="btn btn-info font-weight-bold font-size-sm mr-5 mb-5"
          disabled={pt === "" || en === ""}
        >
          Adicionar
        </button>
      </DialogActions>
    </Dialog>
  );
};
