import React, { useEffect, useState } from "react";
import { useSubheader } from "../../_metronic/layout";
import { RouteTable } from "../components/RouteTable";
import { getRoutes } from '../../redux/crud/RouteCrud';
import { useSelector, useDispatch } from "react-redux";
import { actions } from '../../redux/ducks/routes.duck';
import { Loading } from "../components/Loading";

export const RoutesPage = () => {
  const subheader = useSubheader();
  subheader.setTitle("Percursos");
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const token = useSelector(state => state.auth.token);
  const routes = useSelector(state => state.routes.routes);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  useEffect(() => {
    enableLoading();
    getRoutes(token)
      .then(({ data }) => {
        disableLoading();
        dispatch(actions.setRoutes(data));
      })
      .catch((e) => {
      });

  }, [])



  return (<>
    <div className="col-lg-12">
      {
        routes && (
          <RouteTable routes={routes} className="card-stretch gutter-b" />
        )
      }
    </div>
    {loading && (
      <Loading />
    )}
  </>);
};
