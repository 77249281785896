export const actionTypes = {
  SetAccesses: "[SetAccesses] Action"
};

const initialState = {
  accesses: [],
};


export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetAccesses: {
      const { accesses } = action.payload;
      return { ...state, accesses };
    }
    default:
      return state;
  }
}

export const actions = {
  setAccesses: (accesses) => ({ type: actionTypes.SetAccesses, payload: { accesses } })
};

