import React from "react";
import { useSelector } from "react-redux";

export function QuickUserToggler() {
  const { user } = useSelector(state => state.auth);

  return (<>
    <div className="topbar-item">
      <div className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
        id="kt_quick_user_toggle">
        <>
          <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Olá,</span>
          <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
            {user.name}
          </span>
          <span className="symbol symbol-40 symbol-light-success">
            <span className="symbol-label font-size-h5 font-weight-bold">{user.name.charAt(0)}</span>
          </span>
        </>
      </div>
    </div>
  </>
  );
}
