import React, { useState } from "react";
import { DialogTitle, Dialog, DialogContent, DialogActions, TextField } from "@material-ui/core";

export const InputDialogForm = ({ open, onclose, title, submit }) => {

  const [key, setKey] = useState("");
  const [pt, setPt] = useState("");
  const [en, setEn] = useState("");


  return (
    <Dialog
      open={open}
      onClose={onclose}
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <TextField
          id="outlined-basic"
          variant="outlined"
          label="Key"
          fullWidth
          className="mb-3"
          value={key}
          onChange={(event) => {
            setKey(event.target.value);
          }}
        />
        <TextField
          id="outlined-basic"
          variant="outlined"
          label="PT"
          fullWidth
          className="mb-3"
          value={pt}
          onChange={(event) => {
            setPt(event.target.value);
          }}
        />
        <TextField
          id="outlined-basic"
          variant="outlined"
          label="EN"
          fullWidth
          value={en}
          onChange={(event) => {
            setEn(event.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <button
          onClick={()=>{
            submit(key, pt, en);
            setKey("");
            setPt("");
            setEn("");
          }}
          className="btn btn-info font-weight-bold font-size-sm mr-5 mb-5"
          disabled={key === "" || pt === "" || en === ""}
        >
          Adicionar
        </button>
      </DialogActions>
    </Dialog>
  );
};
