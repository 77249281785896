import React, { useState } from "react";
import { URL } from "../../../redux/config";


export const Table = ({ columns, data }) => {

  return (
    <table className="table">
      <thead>
        <tr>
          {
            columns && columns.map((column, index) => <th key={`label-${index}`} scope="col">{column.label}</th>)
          }
        </tr>
      </thead>
      <tbody>
        {data && data.map((row, index) => {
          return <tr key={`row-${index}`}>
            {
              columns && columns.map((column, index2) => {
                if (column.template) {
                  return <td key={`row2-${index2}`}>{column.template(row)}</td>
                } else {
                  if (column.primary) {
                    return <th key={`row2-${index2}`} scope="row">{row[column.name]}</th>
                  } else {
                    return <td key={`row2-${index2}`}>{row[column.name]}</td>
                  }
                }
              })
            }
          </tr>
        })}
      </tbody>
    </table>
  )
}

export const Pagination = ({total, page, size, isLoading, getUsers}) => {
  const totalPages = Math.round(total/size)
  return (
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="d-flex flex-wrap py-2 mr-3">
          {page != 1 &&
            <a onClick={()=> getUsers(1)} className="btn btn-icon btn-sm btn-light mr-2 my-1">
              <i className="ki ki-bold-double-arrow-back icon-xs"></i>
            </a>
          }
          {page != 1 &&
            <a onClick={()=> getUsers(page-1)} className="btn btn-icon btn-sm btn-light mr-2 my-1">
              <i className="ki ki-bold-arrow-back icon-xs"></i>
            </a>
          }

          {page != totalPages &&
            <a onClick={()=> getUsers(page+1)} href="#" className="btn btn-icon btn-sm btn-light mr-2 my-1">
              <i className="ki ki-bold-arrow-next icon-xs"></i>
            </a>
          }
          {page != totalPages &&
            <a onClick={() => getUsers(totalPages)} href="#" className="btn btn-icon btn-sm btn-light mr-2 my-1">
              <i className="ki ki-bold-double-arrow-next icon-xs"></i>
            </a>
          }
        </div>
        <div className="d-flex align-items-center py-3">
          {isLoading && <div className="d-flex align-items-center">
            <div className="mr-2 text-muted">Loading...</div>
            <div className="spinner mr-10"></div>
          </div>}

          <span className="text-muted">Displaying {page} of {totalPages} pages</span>
        </div>
      </div>
  )
}

export const UsersTable = ({ users, title, subtitle, loading, getUsers }) => {

  const columns = [
    {
      name: "id",
      label: "#",
      primary: true
    },
    {
      name: "img",
      label: "Foto",
      template: row => {
        return (
          <>
            {
              row.photo && row.photo.content_base64 &&
              <img
                style={{ width: 24, height: 24, objectFit: "fill" }}
                src={row.photo.url ? row.photo.url : URL + row.photo.content_base64}
              />
            }
            {
              !row.photo || !row.photo.content_base64 && "N/A"
            }
          </>
        )
      }
    },
    {
      name: "name",
      label: "Nome"
    },
    {
      name: "email",
      label: "Email"
    },
    {
      name: "country",
      label: "País"
    },
  ]

  return (
    <div className="card card-custom">
      <div className="card-header flex-wrap border-0 pt-6 pb-0">
        <div className="card-title">
          <h3 className="card-label">{title}
            <span className="text-muted pt-2 font-size-sm d-block">{subtitle}</span>
          </h3>
        </div>
      </div>
      <div className="card-body">
        <Table columns={columns} data={users.data} />
        <Pagination total={users.total} page={users.page} size={users.size} isLoading={loading} getUsers={getUsers}/>
      </div>
    </div>
  )


}
