import React from "react";

export const Table = ({ columns, data }) => {

  return (
    <table className="table">
      <thead>
        <tr>
          {
            columns && columns.map((column, index) => <th key={`label-${index}`} scope="col">{column.label}</th>)
          }
        </tr>
      </thead>
      <tbody>
        {data && data.map((row, index) => {
          return <tr key={`row-${index}`}>
            {
              columns && columns.map((column, index2) => {
                if (column.template) {
                  return <td key={`row2-${index2}`}>{column.template(row)}</td>
                } else {
                  if (column.primary) {
                    return <th key={`row2-${index2}`} scope="row">{row[column.name]}</th>
                  } else {
                    return <td key={`row2-${index2}`}>{row[column.name]}</td>
                  }
                }
              })
            }
          </tr>
        })}
      </tbody>
    </table>
  )
}

export const RouteFeedbackTable = ({ data, title, subtitle }) => {

  const columns = [
    {
      name: "id",
      label: "#",
      primary: true
    },
    {
      name: "type",
      label: "Tipo",
      template: row => row.type.name.pt
    },
    {
      name: "feedback",
      label: "Feedback",
      template: row => row.text
    }
  ]

  return (
    <div className="card card-custom">
      <div className="card-header flex-wrap border-0 pt-6 pb-0">
        <div className="card-title">
          <h3 className="card-label">{title}
            <span className="text-muted pt-2 font-size-sm d-block">{subtitle}</span>
          </h3>
        </div>
      </div>
      <div className="card-body">
        
        {
          data.length > 0 && 
          <Table columns={columns} data={data} />
        }
         {
          data.length === 0 && 
          <p>Não há feedback registado para este percurso.</p>
        }
      </div>
    </div>
  )


}
