import React from "react";
import { CirclePicker } from 'react-color';

export const ColorPicker = ({currentColor, setColor}) => {

  const handleChange = (color)=> {
    setColor(color.hex)
  }

  return (
    <div className="d-flex flex-row align-items-center">
      <div
        className="mr-10"
        style={{
          width: 50, 
          height: 50, 
          backgroundColor: currentColor,
          borderRadius: 50
        }}
      />
      <CirclePicker 
        colors={["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", 
        "#03a9f4", "#00bcd4", "#0199B4", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", 
        "#ff9800", "#ff5722", "#795548", "#607d8b"]} 
        color={currentColor} 
        onChange={handleChange}
      />
    </div>
  );
};
