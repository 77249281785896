import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useSelector } from "react-redux";
import { updateInformationInfo } from "../../../redux/crud/InformationCrud";

export const IntroCard = ({ updateInfo }) => {
  const token = useSelector(({ auth }) => auth.token);
  const info = useSelector(({ information }) => information.info);

  useEffect(() => {
    if (info) {
      setIntroPT(info.intro.pt);
      setIntroEN(info.intro.gb);
    }
  }, [info]);

  const [introPT, setIntroPT] = useState("");
  const [introEN, setIntroEN] = useState("");

  const [touched, setTouched] = useState(false);

  const save = () => {
    updateInformationInfo(token, introPT, introEN).then(() => {
      setTouched(false);
      updateInfo();
    });
  };

  const quillModules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
    ],
  };

  return (
    <div id="intro">
      {info && (
        <div className="card card-custom card-stretch gutter-b">
          <div className="card-header border-0 py-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label font-weight-bolder text-dark">
                Introdução
              </span>
            </h3>
          </div>
          <div className="card-body pt-0 pb-4 d-flex flex-column flex-wrap mb-5">
            <span className="text-dark-75 pl-0 text-left font-weight-bolder mr-3 mb-3 font-size-lg col-3">
              Introdução PT
            </span>
            <ReactQuill
              theme="snow"
              style={{ width: "100%", marginBottom: "10px" }}
              value={introPT}
              onChange={(value) => {
                if (!touched) {
                  setTouched(true);
                }
                setIntroPT(value);
              }}
              placeholder="PT"
              modules={quillModules}
            />
            <span className="text-dark-75 pl-0 text-left font-weight-bolder mr-3 mb-3 font-size-lg col-3">
              Introdução EN
            </span>
            <ReactQuill
              theme="snow"
              style={{ width: "100%" }}
              value={introEN}
              onChange={(value) => {
                if (!touched) {
                  setTouched(true);
                }
                setIntroEN(value);
              }}
              placeholder="EN"
              modules={quillModules}
            />
          </div>
          <div className="d-flex justify-content-end">
            {touched && (
              <button
                onClick={save}
                id="add-button"
                className="btn btn-info font-weight-bolder font-size-lg mb-5 mr-10"
              >
                Guardar Alterações
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
