import React, { useEffect } from "react";
import { getAccesses, deleteAccess, addAccess } from "../../../redux/crud/AccessCrud";
import { useSelector, useDispatch } from "react-redux";
import { ContentTable } from "./ContentTable";
import { actions } from '../../../redux/ducks/access.duck';

export const AccessCard = () => {
  const dispatch = useDispatch();
  const token = useSelector(state => state.auth.token);
  const accesses = useSelector(state => state.accesses.accesses);

  const getAccessLocal = () => {
    getAccesses(token).then(({ data }) => {
      dispatch(actions.setAccesses(data));
    })
  }

  const deleteAccessLocal = (id) => {
    deleteAccess(token, id).then(() => {
      getAccessLocal();
    })
  }

  const addAccessLocal = (key, pt, en) => {
    addAccess(token, key, pt, en).then(() => {
      getAccessLocal();
    });
  }

  useEffect(() => {
    getAccessLocal();
  }, [])


  return (
    <ContentTable 
      data={accesses}
      title={"Lista de Acessos"}
      subtitle={"Pode Consultar, Adicionar e Apagar Acessos"}
      addRow={addAccessLocal}
      deleteRow={deleteAccessLocal}
      addDialogTitle={"Adicionar Acessos"}
    />
   );
};
