export const actionTypes = {
  SetReviews: "[SetReviews] Action"
};

const initialState = {
  reviews: [],
};


export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetReviews: {
      const { reviews } = action.payload;
      return { ...state, reviews };
    }
    default:
      return state;
  }
}

export const actions = {
  setReviews: (reviews) => ({ type: actionTypes.SetReviews, payload: { reviews } })
};

