import React, { useState, useCallback } from "react";
import { useDropzone } from 'react-dropzone';
import { DialogTitle, Dialog, DialogActions, TextField, DialogContent, DialogContentText } from "@material-ui/core";

export const ImageInputDialog = ({ open, onClose, submit, hasPosition }) => {

  const [url, setUrl] = useState(null);
  const [b64, setB64] = useState(null);
  const [position, setPosition] = useState("")

  const onDrop = useCallback(acceptedFiles => {
    var imgFile = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      setB64(event.target.result);
      setUrl(null);
    }
    reader.readAsDataURL(imgFile)
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*'
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
    >
      <DialogTitle id="form-dialog-title">Adicionar Imagem</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Adicionar URL ou fazer Upload de uma imagem.
        </DialogContentText>
        {
          hasPosition &&
          <TextField
            id="outlined-basic"
            variant="outlined"
            label="Posição"
            fullWidth
            type="number"
            className="mb-3"
            value={position}
            onChange={(event) => {
              setPosition(event.target.value);
            }}
          />
        }

        <TextField
          id="outlined-basic"
          variant="outlined"
          label="URL"
          fullWidth
          className="mb-3"
          value={url ? url : ""}
          onChange={(event) => {
            setUrl(event.target.value);
            setB64(null)
          }}
        />
        <div className="drag-input" {...getRootProps()}>
          <input className="p0" {...getInputProps()} />
          {
            isDragActive ?
              <p>Arrastar ficheiro para aqui ...</p> :
              <p>Arrastar ficheiros para aqui ou carregar para selecionar</p>
          }
        </div>

        <div id="image-preview" className="d-flex mt-4 justify-content-center">
          {
            url &&
            <img className="route-image" src={url} alt="URL inválido" />
          }
          {
            b64
            &&
            <img className="route-image" src={b64} />
          }
        </div>


      </DialogContent>
      <DialogActions className="mb-5 justify-content-center">
        <button
          onClick={() => submit(url, b64, position)}
          disabled={url === null && b64 === null}
          className="btn btn-info font-weight-bold font-size-sm"
        >
          Adicionar
        </button>
      </DialogActions>
    </Dialog>
  );
};
