import React, { useState } from "react";
import { useSelector } from "react-redux";
import { addRoutePhoto, deleteRoutePhoto } from '../../../redux/crud/RouteCrud';
import { ImageInputDialog } from "../ImageInputDialog";
import { ConfirmDialog } from "../ConfirmDialog";
import { RouteImage } from "./RouteImage";

export const Images = ({ route, updateRoute }) => {
  const token = useSelector(state => state.auth.token);

  const [imageDialog, setImageDialog] = useState(false);
  const [imageDeleteDialog, setImageDeleteDialog] = useState(false);

  const [deletePhotoId, setDeletePhotoId] = useState(null);

  const handleAddImage = (url, b64, position) => {
    addRoutePhoto(token, route.id, url, b64, position).then(() => {
      setImageDialog(false);
      updateRoute();
    })
  }

  const handleDeleteImage = () => {
    deleteRoutePhoto(token, route.id, deletePhotoId).then(() => {
      setDeletePhotoId(null);
      updateRoute();
    })
  }

  return (

    <div id="images" className="col-lg-12">
      <div className="card card-custom card-stretch gutter-b">
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">Imagens</span>
          </h3>
          <div className="card-toolbar">
            <button onClick={() => setImageDialog(true)} className="btn btn-info font-weight-bolder font-size-sm">
              Adicionar Imagem
            </button>
          </div>
        </div>
        <div className="card-body pt-0 pb-4 d-flex flex-row flex-wrap">
          {
            route.photos.map((photo, index) =>
              <RouteImage 
                key={`trail-photo-${index}`}
                photo={photo}
                token={token}
                setImageDeleteDialog={setImageDeleteDialog}
                setDeletePhotoId={setDeletePhotoId}
              />
            )
          }
        </div>
      </div>
      <ImageInputDialog
        hasPosition={true}
        open={imageDialog}
        onClose={() => setImageDialog(false)}
        submit={(url, b64, position) => handleAddImage(url, b64, position)}
      />
      <ConfirmDialog
        title="Apagar Imagem?"
        open={imageDeleteDialog}
        onclose={() => setImageDeleteDialog(false)}
        yes={() => {
          setImageDeleteDialog(false);
          handleDeleteImage();
        }}
        no={() => {
          setImageDeleteDialog(false);
        }}

      />
    </div>
  );
};
