import React, { useState, useEffect } from "react";
import { useSubheader } from "../../_metronic/layout";
import { useSelector } from "react-redux";
import { getRoute } from '../../redux/crud/RouteCrud';
import { Loading } from "../components/Loading";
import { useParams } from "react-router-dom";
import { Filters } from "../components/routePage/Filters";
import { TrailInfo } from "../components/routePage/TrailInfo";
import { Images } from "../components/routePage/Images";
import { Graph } from "../components/routePage/Graph";
import { Tips } from "../components/routePage/Tips";
import { RouteMap } from "../components/routePage/RouteMap";
import { DeleteRoute } from "../components/routePage/DeleteRoute";

export const RoutePage = () => {
  const subheader = useSubheader();
  subheader.setTitle("Editar Percurso");
  let { id } = useParams();
  const token = useSelector(state => state.auth.token);
  const [loading, setLoading] = useState(true);
  const [route, setRoute] = useState(null);

  const [color, setColor] = useState("#0199B4");

  useEffect(() => {
    getRouteLocal();
  }, []);

  const getRouteLocal = () => {
    getRoute(token, id).then(({ data }) => {
      setRoute(data);
      setColor(data.color);
      setLoading(false);
    });
  }

  return (<>
    {
      route &&
      <div className="d-flex flex-row flex-wrap">
        <TrailInfo route={route} updateRouteLocal={getRouteLocal}/>
        <RouteMap color={color}/>
        <Images route={route} updateRoute={getRouteLocal}/>
        <Tips route={route} updateRoute={getRouteLocal}/>
        <Filters route={route} updateRoute={getRouteLocal}/>
        <Graph route={route} updateRoute={getRouteLocal}/>
        <DeleteRoute route={route}/>
      </div>
    }
    {loading && (
      <Loading />
    )}
  </>);
};
