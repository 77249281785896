import React, { useState, useEffect } from "react";
import Fab from '@material-ui/core/Fab';
import { Delete } from '@material-ui/icons';
import { URL } from "../../../redux/config";
import { TextField } from "@material-ui/core";
import { updatePhotoPosition } from "../../../redux/crud/RouteCrud";

export const RouteImage = ({ token, photo, setImageDeleteDialog, setDeletePhotoId }) => {

  const [position, setPosition] = useState("");

  useEffect(() => {
    setPosition(photo.position)
  }, [photo])

  useEffect(() => {
    if (position !== photo.position) {
      updatePhotoPosition(token, photo.id, position)
    }
  }, [position])

  return (

    <div className="d-flex align-items-start m-6">
      <img
        style={{ width: 320, height: 170, objectFit: "fill" }}
        src={photo.url ? photo.url : URL + photo.content_base64}
      />
      <div
        className="d-flex flex-column align-items-center justify-content-center"
        style={{ height: '100%', marginLeft: '1rem' }}
      >
        <Fab
          onClick={() => {
            setDeletePhotoId(photo.id);
            setImageDeleteDialog(true);
          }}
          size="small"
          style={{ backgroundColor: "coral", color: "white" }}
          aria-label="add"
        >
          <Delete />
        </Fab>
        <div>
          <TextField
            variant="outlined"
            style={{ width: "60px", marginTop: '1rem', }}
            className="mb-3"
            type="number"
            value={position}
            onChange={(event) => {
              setPosition(event.target.value);
            }}
          />
        </div>
      </div>

    </div>

  );
};
