import React, { useState } from "react";
import { useSelector } from "react-redux";
import Fab from '@material-ui/core/Fab';
import { Delete } from '@material-ui/icons';
import { ImageInputDialog } from "../ImageInputDialog";
import { ConfirmDialog } from "../ConfirmDialog";
import { URL } from "../../../redux/config";
import { addInformationImage, deleteInformationImage } from "../../../redux/crud/InformationCrud";

export const ImageCard = ({ updateInfo }) => {
  const token = useSelector(state => state.auth.token);
  const image = useSelector(({ information }) => information.image);

  const [imageDialog, setImageDialog] = useState(false);
  const [imageDeleteDialog, setImageDeleteDialog] = useState(false);


  const handleAddImage = (url, b64) => {
    addInformationImage(token, url, b64).then(()=>{
      updateInfo();
      setImageDialog();
    })
  }

  const handleDeleteImage = () => {
    deleteInformationImage(token, image.photo.id).then(()=>{
      updateInfo();
    })
  }

  return (

    <div id="images" >
      <div className="card card-custom card-stretch gutter-b">
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">Imagem de PRGR</span>
          </h3>
          {
            (image === null || image.photo === null) &&
            <div className="card-toolbar">
            <button onClick={() => setImageDialog(true)} className="btn btn-info font-weight-bolder font-size-sm">
              Adicionar Imagem
            </button>
          </div>
          }
          
        </div>
        <div className="card-body pt-0 pb-4 d-flex flex-row flex-wrap">

          {
            image && image.photo &&
            <div className="d-flex align-items-start m-6">
              <img
                style={{ width: 320, height: 170, objectFit: "fill", marginTop: "2rem" }}
                src={image.photo.url ? image.photo.url : URL + image.photo.content_base64}
              />
              <Fab
                onClick={() => {
                  setImageDeleteDialog(true);
                }}
                size="small"
                style={{ backgroundColor: "coral", color: "white", marginLeft: "3px" }}
                aria-label="add"
              >
                <Delete />
              </Fab>
            </div>
          }



        </div>
      </div>
      <ImageInputDialog
        open={imageDialog}
        onClose={() => setImageDialog(false)}
        submit={(url, b64) => handleAddImage(url, b64)}
      />
      <ConfirmDialog
        title="Apagar Imagem?"
        open={imageDeleteDialog}
        onclose={() => {
          setImageDeleteDialog(false)
          
        }}
        yes={() => {
          setImageDeleteDialog(false);
          handleDeleteImage();
        }}
        no={() => {
          setImageDeleteDialog(false);
        }}

      />
    </div>
  );
};
