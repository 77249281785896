import { URL } from '../config';
import Axios from 'axios';

export const TOPOGRAPHY_URL = "/topography"

export function getTopographies(token) {
  return Axios.get(`${URL}${TOPOGRAPHY_URL}`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }
  );
}

export function deleteTopography(token, id) {
  return Axios.delete(`${URL}${TOPOGRAPHY_URL}/${id}`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  })
}

export function addTopography(token, key, namePT, nameEN, feetPT, feetEN) {

  return Axios({
    method: 'post',
    url: `${URL}${TOPOGRAPHY_URL}`,
    headers: {
      "Authorization": `Bearer ${token}`
    },
    data: {
      "key": key,
      "name": {
        "pt": namePT,
        "gb": nameEN
      },
      "name_feet": {
        "pt": feetPT,
        "gb": feetEN
      }
    }
  })
}