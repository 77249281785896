import React from "react";
import { DialogTitle, Dialog, DialogActions } from "@material-ui/core";

export const ConfirmDialog = ({ open, onclose, title, yes, no }) => {

  return (
    <Dialog
      open={open}
      onClose={onclose}
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogActions className="mb-5 justify-content-center">
        <button
          onClick={yes}
          className="btn btn-info font-weight-bold font-size-sm"
        >
          Sim
        </button>
        <button
          onClick={no}
          className="btn btn-warning font-weight-bold font-size-sm"
        >
          Não
        </button>
      </DialogActions>
    </Dialog>
  );
};
