import React from "react";

export const Table = ({ columns, data }) => {

  return (
    <table className="table">
      <thead>
        <tr>
          {
            columns && columns.map((column, index) => 
            <th 
              key={`label-${index}`} 
              style={{minWidth: column.minWidth, width: column.width}} 
              scope="col"
            >
              {column.label}
            </th>)
          }
        </tr>
      </thead>
      <tbody>
        {data && data.map((row, index) => {
          return <tr key={`row-${index}`}>
            {
              columns && columns.map((column, index2) => {
                if (column.template) {
                  return <td key={`row2-${index2}`}>{column.template(row)}</td>
                } else {
                  if (column.primary) {
                    return <th key={`row2-${index2}`} scope="row">{row[column.name]}</th>
                  } else {
                    return <td key={`row2-${index2}`}>{row[column.name]}</td>
                  }
                }
              })
            }
          </tr>
        })}
      </tbody>
    </table>
  )
}

export const RouteReviewApprovedTable = ({ data, title, subtitle, disaprove, remove }) => {

  const columns = [
    {
      name: "id",
      label: "#",
      primary: true
    },
    {
      name: "user",
      label: "Nome",
      template: row => row.user.name
    },
    {
      name: "country",
      label: "País",
      template: row => row.user.country
    },
    {
      name: "rating",
      label: "Rating",
      template: row => row.rating
    },
    {
      name: "comment",
      label: "Comentário",
      //minWidth: 500,
      template: row => row.comment
    },    
    {
      name: "state",
      label: "Estado",
      minWidth: 96,
      template: row => row.approved ? "Aprovado" : "Por Aprovar"
    },
    {
      name: "actions",
      label: "Ações",
      width: 201, 
      template: row => {
        return (
          <>
           <button onClick={()=>disaprove(row.id)} style={{ fontSize: 10, width: 95 }} className="btn btn-warning btn-sm mr-2">
              <i style={{ fontSize: 10 }} className="flaticon2-check-mark"></i>Desaprovar
            </button>
            <button onClick={()=>remove(row.id)} style={{ fontSize: 10,  width: 80 }} className="btn btn-light btn-sm">
              <i style={{ fontSize: 10 }} className="flaticon2-delete"></i>Apagar
            </button>
          </>
        )

      }
    }
  ]

  return (
    <div className="card card-custom">
      <div className="card-header flex-wrap border-0 pt-6 pb-0">
        <div className="card-title">
          <h3 className="card-label">{title}
            <span className="text-muted pt-2 font-size-sm d-block">{subtitle}</span>
          </h3>
        </div>
      </div>
      <div className="card-body">
        
        {
          data.length > 0 && 
          <Table columns={columns} data={data} />
        }
         {
          data.length === 0 && 
          <p>Não há reviews aprovadas neste percurso.</p>
        }
      </div>
    </div>
  )


}
