import React, { useEffect } from "react";
import { getAttributes, addAttribute, deleteAttribute, editAttribute } from "../../../redux/crud/AttributeCrud";
import { useSelector, useDispatch } from "react-redux";
import { actions } from '../../../redux/ducks/attribute.duck';
import { AttributeTable } from "./AttributeTable";

export const AttributeCard = () => {
  const dispatch = useDispatch();
  const token = useSelector(state => state.auth.token);
  const attributes = useSelector(state => state.attributes.attributes);

  const getAttributeLocal = () => {
    getAttributes(token).then(({ data }) => {
      dispatch(actions.setAttributes(data));
    })
  }

  const deleteAttributeLocal = (id) => {
    deleteAttribute(token, id).then(() => {
      getAttributeLocal();
    })
  }

  const addAttributeLocal = (key, pt, en, url, b64, inList) => {
    addAttribute(token, key, pt, en, url, b64, inList).then(() => {
      getAttributeLocal();
    });
  }

  const editRowLocal = (id, key, pt, en, url, b64, inList) => {
    editAttribute(token, id, key, pt, en, url, b64, inList).then(()=> {
      getAttributeLocal();
    })
  }

  useEffect(() => {
    getAttributeLocal();
  }, [])


  return (
    <AttributeTable
      data={attributes}
      title={"Lista de Atributos"}
      subtitle={"Pode Consultar, Adicionar e Apagar Atributos"}
      addRow={addAttributeLocal}
      deleteRow={deleteAttributeLocal}
      addDialogTitle={"Adicionar Atributo"}
      editRow={editRowLocal}
      editDialogTitle={"Editar Atributo"}
    />
   );
};
