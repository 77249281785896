import { URL } from '../config';
import Axios from 'axios';

export const ATTRIBUTE_URL = "/attribute"

export function getAttributes(token) {
  return Axios.get(`${URL}${ATTRIBUTE_URL}`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }
  );
}

export function addAttribute(token, key, namePT, nameEN, url, b64, inList) {

  return Axios({
    method: 'post',
    url: `${URL}${ATTRIBUTE_URL}`,
    headers: {
      "Authorization": `Bearer ${token}`
    },
    data: {
      "key": key,
      "name": {
        "pt": namePT,
        "gb": nameEN
      },
      "photo_b64": b64,
      "photo_url": url,
      "in_list": inList
    }
  })
}

export function deleteAttribute(token, id) {
  return Axios.delete(`${URL}${ATTRIBUTE_URL}/${id}`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  })
}

export function editAttribute(token, id, key, namePT, nameEN, url, b64, inList) {

  return Axios({
    method: 'post',
    url: `${URL}${ATTRIBUTE_URL}/${id}`,
    headers: {
      "Authorization": `Bearer ${token}`
    },
    data: {
      "key": key,
      "name": {
        "pt": namePT,
        "gb": nameEN
      },
      "photo_b64": b64,
      "photo_url": url,
      "in_list": inList
    }
  })
}
