import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {actions} from '../../../redux/ducks/users.duck';
import {UsersTable} from "./UsersTable";
import {getUsers} from "../../../redux/crud/UsersCrud";

export const UsersCard = () => {
    const dispatch = useDispatch();
    const token = useSelector(state => state.auth.token);
    const users = useSelector(state => state.users.users);
    const [loading, setLoading] = useState(false)


    const getUsersLocal = (page, size=10) => {
        setLoading(true)
        getUsers(token, page, size).then(({data}) => {
            setLoading(false)
            dispatch(actions.setUsers(data))
        }).catch(err => {
            setLoading(false)
        })
    }

    useEffect(() => {
        getUsersLocal();
    }, [])


    return (
        <UsersTable getUsers={getUsersLocal}
                    users={users}
                    loading={loading}
                    title={"Lista de Utilizadores"}
                    subtitle={"Pode Consultar os Utilizadores"}
        />
    );
};
