import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action"
};

const initialAuthState = {
  user: undefined,
  token: undefined
};

export const reducer = persistReducer(
  { storage, key: "auth", whitelist: ["user", "token"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { token, email, name, id, photo } = action.payload;
        return { token, user: {"email": email, "name": name, "id": id, "photo": photo} };
      }

      case actionTypes.Logout: {
        return {...state, user: undefined, token: undefined};
      }

      // case actionTypes.UserLoaded: {
      //   const { user } = action.payload;
      //   return { ...state, user };
      // }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (token, email, name, id, photo) => ({ type: actionTypes.Login, payload: { token, email, name, id, photo } }),
  logout: () => ({type: actionTypes.Logout})
};

export function* saga() {
  // yield takeLatest(actionTypes.Login, function* loginSaga() {
  //   yield put(actions.requestUser());
  // });

  // yield takeLatest(actionTypes.Register, function* registerSaga() {
  //   yield put(actions.requestUser());
  // });

  // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
  //   const { data: user } = yield getUserByToken();

  //   yield put(actions.fulfillUser(user));
  // });
}
