export const actionTypes = {
  SetSponsors: "[SetSponsors] Action"
};

const initialState = {
  sponsors: [],
};


export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetSponsors: {
      const { sponsors } = action.payload;
      return { ...state, sponsors };
    }
    default:
      return state;
  }
}

export const actions = {
  setSponsors: (sponsors) => ({ type: actionTypes.SetSponsors, payload: { sponsors } })
};

