export const actionTypes = {
  SetUsers: "[SetUsers] Action"
};

const initialState = {
  users: [],
};


export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetUsers: {
      const { users } = action.payload;
      return { ...state, users };
    }
    default:
      return state;
  }
}

export const actions = {
  setUsers: (users) => ({ type: actionTypes.SetUsers, payload: { users } })
};

