import React from "react";
import { URL } from "../../redux/config";

export const TableImage = ({ photo }) => {
  return (
    <>
      {
        photo && (
        photo.url ?
          (
            <img
              style={{ width: 50, height: 50, objectFit: 'cover', borderRadius: 4 }}
              src={photo.url}
            />
          )
          : (
            <img
              style={{ width: 50, height: 50, objectFit: 'cover', borderRadius: 4 }}
              src={URL + photo.content_base64}
            />
          ))
      }
    </>

  );
};
