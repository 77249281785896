import React, { useEffect, useState } from "react";
import { useSubheader } from "../../_metronic/layout";
import { FeedbackTypeCard } from "../components/feedbackBoard/FeedbackTypeCard";
import { useSelector, useDispatch } from "react-redux";
import { FeedbackTable } from "../components/feedbackBoard/FeedbackTable";
import { Loading } from "../components/Loading";
import { actions as feedbackActions } from '../../redux/ducks/feedback.duck';
import { getFeedback } from "../../redux/crud/FeedbackCrud";

export const FeedbackBoard = () => {
  const subheader = useSubheader();
  subheader.setTitle("Feedback");

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const token = useSelector(state => state.auth.token);
  const feedback = useSelector(state => state.feedback.feedback);

  useEffect(() => {
    setLoading(true);
    getFeedback(token).then(({ data }) => {
      setLoading(false)
      dispatch(feedbackActions.setFeedback(data));
    })
  }, [])

  return (<>
    <div className="col-lg-12 mb-8">
      {
        feedback &&
        <FeedbackTable routes={feedback} />
      }
    </div>
    <div className="col-lg-12 mb-8">
      <FeedbackTypeCard />
    </div>
    {loading && (
      <Loading />
    )}
  </>);
};
