import React, { useState } from "react";
import { useSubheader } from "../../_metronic/layout";
import { useSelector } from "react-redux";
import { addRoute } from '../../redux/crud/RouteCrud';
import TextField from '@material-ui/core/TextField';
import { ColorPicker } from "../components/ColorPicker";
import { useHistory } from "react-router-dom";

export const NewRoutePage = () => {
  const subheader = useSubheader();
  subheader.setTitle("Adicionar Percurso");
  const history = useHistory();

  const token = useSelector(state => state.auth.token);

  const [namePT, setNamePT] = useState("");
  const [nameEN, setNameEN] = useState("");
  const [subtitlePT, setSubtitlePT] = useState("");
  const [subtitleEN, setSubtitleEN] = useState("");
  const [descriptionPT, setDescriptionPT] = useState("");
  const [descriptionEN, setDescriptionEN] = useState("");
  const [position, setPosition] = useState("");
  const [lengthMetric, setLengthMetric] = useState("");
  const [lengthImperial, setLengthImperial] = useState("");
  const [heightMetric, setHeightMetric] = useState("");
  const [heightImperial, setHeightImperial] = useState("");
  const [color, setColor] = useState("#0199B4");
  const [geojson, setGeojson] = useState("");


  const handleSubmit = () => {

    addRoute(token, geojson, namePT, nameEN, subtitlePT, subtitleEN, descriptionPT, descriptionEN,
      lengthMetric, lengthImperial, heightMetric, heightImperial, color, position)
      .then(({ data }) => {
        console.log(data)
        history.push("/percursos/" + data.id);
      })
      .catch((e) => {
      });
  }


  return (<>
    <div className="col-lg-12">
      <div className="card card-custom card-stretch gutter-b">
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">Adicionar Percurso</span>
          </h3>
        </div>
        <div className="card-body pt-0 pb-3">
          <div id="name-input" className="d-flex align-items-center mb-10">
            <span className="text-dark-75 font-weight-bolder mr-3 font-size-lg col-2">
              Nome
            </span>
            <div className="d-flex align-items-center flex-column col-10">
              <TextField
                id="outlined-basic"
                variant="outlined"
                label="PT"
                fullWidth
                className="mb-3"
                value={namePT}
                onChange={(event) => {
                  setNamePT(event.target.value);
                }}
              />
              <TextField
                id="outlined-basic"
                variant="outlined"
                label="EN"
                fullWidth
                value={nameEN}
                onChange={(event) => {
                  setNameEN(event.target.value);
                }}
              />
            </div>
          </div>
          <div id="subtitle_input" className="d-flex align-items-center mb-10">
            <span className="text-dark-75 font-weight-bolder mr-3 font-size-lg col-2">
              Subtítulo
            </span>
            <div className="d-flex align-items-center flex-column col-10">
              <TextField
                id="outlined-basic"
                variant="outlined"
                label="PT"
                fullWidth
                className="mb-3"
                multiline
                value={subtitlePT}
                onChange={(event) => {
                  setSubtitlePT(event.target.value);
                }}
              />
              <TextField
                id="outlined-basic"
                variant="outlined"
                label="EN"
                fullWidth
                multiline
                value={subtitleEN}
                onChange={(event) => {
                  setSubtitleEN(event.target.value);
                }}
              />
            </div>
          </div>
          <div id="description_input" className="d-flex align-items-center mb-10">
            <span className="text-dark-75 font-weight-bolder mr-3 font-size-lg col-2">
              Descrição
            </span>
            <div className="d-flex align-items-center flex-column col-10">
              <TextField
                id="outlined-basic"
                variant="outlined"
                label="PT"
                fullWidth
                className="mb-3"
                multiline
                value={descriptionPT}
                onChange={(event) => {
                  setDescriptionPT(event.target.value);
                }}
              />
              <TextField
                id="outlined-basic"
                variant="outlined"
                label="EN"
                fullWidth
                multiline
                value={descriptionEN}
                onChange={(event) => {
                  setDescriptionEN(event.target.value);
                }}
              />
            </div>
          </div>
          <div id="position-input" className="d-flex align-items-center mb-10">
            <span className="text-dark-75 font-weight-bolder mr-3 font-size-lg col-2">
              Posição
            </span>
            <div className="d-flex align-items-center flex-column col-10">
              <TextField
                id="outlined-basic"
                variant="outlined"
                label="Posição"
                fullWidth
                className="mb-3"
                type={"number"}
                value={position}
                onChange={(event) => {
                  setPosition(event.target.value);
                }}
              />
            </div>
          </div>
          <div id="length-input" className="d-flex align-items-center mb-10">
            <span className="text-dark-75 font-weight-bolder mr-3 font-size-lg col-2">
              Comprimento
            </span>
            <div className="d-flex align-items-center flex-column col-10 ">
              <TextField
                id="outlined-basic"
                variant="outlined"
                label="Kilometros"
                fullWidth
                className="mb-3"
                type="number"
                value={lengthMetric}
                onChange={(event) => {
                  setLengthMetric((Math.round(event.target.value * 100) / 100).toFixed(2));
                  setLengthImperial((Math.round(event.target.value * 0.621371192 * 100) / 100).toFixed(2));
                }}
              />
              <TextField
                id="outlined-basic"
                variant="outlined"
                label="Milhas"
                fullWidth
                type="number"
                value={lengthImperial}
                onChange={(event) => {
                  setLengthImperial((Math.round(event.target.value * 100) / 100).toFixed(2));
                  setLengthMetric((Math.round((event.target.value / 0.621371192) * 100) / 100).toFixed(2));
                }}
              />
            </div>
          </div>
          <div id="height-input" className="d-flex align-items-center mb-10">
            <span className="text-dark-75 pl-0 font-weight-bolder mr-3 font-size-lg col-2">
              Altura
              </span>
            <div className="d-flex align-items-center flex-column col-10 ">
              <TextField
                variant="outlined"
                label="Metros"
                fullWidth
                className="mb-3"
                type="number"
                value={heightMetric}
                onChange={(event) => {
                  setHeightMetric((Math.round(event.target.value * 100) / 100).toFixed(2));
                  setHeightImperial((Math.round(event.target.value * 3.2808399 * 100) / 100).toFixed(2));
                }}
              />
              <TextField
                variant="outlined"
                label="Feet"
                fullWidth
                type="number"
                value={heightImperial}
                onChange={(event) => {
                  setHeightImperial((Math.round(event.target.value * 100) / 100).toFixed(2));
                  setHeightMetric((Math.round((event.target.value / 3.2808399) * 100) / 100).toFixed(2));
                }}
              />
            </div>
          </div>
          <div id="color-picker" className="d-flex align-items-center mb-10">
            <span className="text-dark-75 font-weight-bolder mr-3 font-size-lg col-2">
              Cor
            </span>
            <div className="d-flex flex-column col-10 ">
              <ColorPicker
                currentColor={color}
                setColor={setColor}
              />
            </div>
          </div>
          <div id="geojson-input" className="d-flex align-items-center mb-10">
            <span className="text-dark-75 font-weight-bolder mr-3 font-size-lg col-2">
              Geojson
            </span>
            <div className="d-flex flex-column col-10">
              <TextField
                id="outlined-basic"
                variant="outlined"
                label="Geojson"
                multiline
                rows={16}
                fullWidth
                className="mb-3"
                value={geojson}
                onChange={(event) => {
                  setGeojson(event.target.value);
                }}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end mt-5">
            <button
              onClick={handleSubmit}
              id="add-button"
              disabled={namePT === "" || descriptionPT === "" || lengthMetric === ""
                || lengthImperial === "" || geojson === "" || subtitlePT === ""}
              className="btn btn-info font-weight-bolder font-size-lg mb-5 w-25"
            >
              Adicionar Percurso
            </button>
          </div>
        </div>
      </div>
    </div>
  </>);
};
