import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { DashboardPage } from "./pages/DashboardPage";
import { RoutesPage } from "./pages/RoutesPage";
import { NewRoutePage } from "./pages/NewRoutePage";
import { RoutePage } from "./pages/RoutePage";
import { ContentBoard } from "./pages/ContentBoard";
import { DatatableDemo } from "./pages/DatatableDemo";
import { AttributeBoard } from "./pages/AttributeBoard";
import { FeedbackBoard } from "./pages/FeedbackBoard";
import { FeedbackPage } from "./pages/FeedbackPage";
import { ReviewsBoard } from "./pages/ReviewsBoard";
import { ReviewsPage } from "./pages/ReviewsPage";
import { InformationBoard } from "./pages/InformationBoard";
import { SponsorBoard } from "./pages/SponsorBoard";
import { UsersPage } from "./pages/UsersPage";
import { TermsAndConditionsPage } from "./pages/TermsAndConditionsPage";

export default function BasePage() {

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          <Redirect exact from="/" to="/percursos" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/percursos/novo" component={NewRoutePage} />
        <ContentRoute path="/percursos/:id" component={RoutePage} />
        <ContentRoute path="/percursos" component={RoutesPage} />
        <ContentRoute path="/datatables" component={DatatableDemo} />
        <ContentRoute path="/filtros" component={ContentBoard} />
        <ContentRoute path="/atributos" component={AttributeBoard} />
        <ContentRoute path="/feedback/:id" component={FeedbackPage} />
        <ContentRoute path="/feedback" component={FeedbackBoard} />
        <ContentRoute path="/reviews/:id" component={ReviewsPage} />
        <ContentRoute path="/reviews" component={ReviewsBoard} />
        <ContentRoute path="/informações" component={InformationBoard}/>
        <ContentRoute path="/patrocinadores" component={SponsorBoard}/>
        <ContentRoute path="/utilizadores" component={UsersPage}/>
        <ContentRoute path="/termos-condicoes" component={TermsAndConditionsPage}/>
        <Redirect to="error" />
      </Switch>
    </Suspense>
  );
}
