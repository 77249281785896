import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useSelector } from "react-redux";
import { addUpdateTerms } from "../../../redux/crud/TermsCrud";

export const TermsCard = ({ updateTerms }) => {
  const token = useSelector(({ auth }) => auth.token);
  const termsAndConditions = useSelector(({ terms }) => terms.terms);

  useEffect(() => {
    if (termsAndConditions) {
      setTermsPT(termsAndConditions.pt);
      setTermsEN(termsAndConditions.gb);
    }
  }, [termsAndConditions]);

  const [termsPT, setTermsPT] = useState("");
  const [termsEN, setTermsEN] = useState("");

  const [touched, setTouched] = useState(false);

  const save = () => {
    addUpdateTerms(token, termsPT, termsEN).then(() => {
      setTouched(false);
      updateTerms();
    });
  };

  const quillModules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
    ],
  };

  return (
    <div id="intro">
      {termsAndConditions && (
        <div className="card card-custom card-stretch gutter-b">
          <div className="card-header border-0 py-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label font-weight-bolder text-dark">
                Termos e Condições
              </span>
            </h3>
          </div>
          <div className="card-body pt-0 pb-4 d-flex flex-column flex-wrap mb-5">
            <span className="text-dark-75 pl-0 text-left font-weight-bolder mr-3 mb-3 font-size-lg col-3">
              Termos e Condições PT
            </span>
            <ReactQuill
              theme="snow"
              style={{ width: "100%", marginBottom: "10px" }}
              value={termsPT}
              onChange={(value) => {
                if (!touched) {
                  setTouched(true);
                }
                setTermsPT(value);
              }}
              placeholder="PT"
              modules={quillModules}
            />
            <span className="text-dark-75 pl-0 text-left font-weight-bolder mr-3 mb-3 font-size-lg col-3">
              Termos e Condições EN
            </span>
            <ReactQuill
              theme="snow"
              style={{ width: "100%" }}
              value={termsEN}
              onChange={(value) => {
                if (!touched) {
                  setTouched(true);
                }
                setTermsEN(value);
              }}
              placeholder="EN"
              modules={quillModules}
            />
          </div>
          <div className="d-flex justify-content-end">
            {touched && (
              <button
                onClick={save}
                id="add-button"
                className="btn btn-info font-weight-bolder font-size-lg mb-5 mr-10"
              >
                Guardar Alterações
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
