export const actionTypes = {
  SetTopographies: "[SetTopographies] Action"
};

const initialState = {
  topographies: [],
};


export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetTopographies: {
      const { topographies } = action.payload;
      return { ...state, topographies };
    }
    default:
      return state;
  }
}

export const actions = {
  setTopographies: (topographies) => ({ type: actionTypes.SetTopographies, payload: { topographies } })
};

