/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import {Link, useLocation} from "react-router-dom";

export function BreadCrumbs({items}) {
  const location = useLocation();

  const [paths, setPaths] = useState([]);

  useEffect(()=>{
    const pathArray = location.pathname.split('/');

    const temp = [];
    let nav = ""

    for(let i= 1; i<pathArray.length; i++){
      nav = nav.concat("/"+pathArray[i])
      temp.push({name: pathArray[i], nav: nav});
    }

    setPaths(temp);

  }, [location])

  if (!items || !items.length) {
      return "";
  }

  return (
    <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2">
      {
        paths.map((path, index)=>
        <li key={`bc${index}`} className="breadcrumb-item">
          <Link className="text-muted" style={{textTransform: 'capitalize'}} to={path.nav} replace={true}>
            {path.name}
          </Link>
        </li>
        )
      }
    </ul>
  );
}
