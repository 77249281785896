import { URL } from '../config';
import Axios from 'axios';

export const ACCESS_URL = "/access"

export function getAccesses(token) {
  return Axios.get(`${URL}${ACCESS_URL}`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }
  );
}

export function deleteAccess(token, id) {
  return Axios.delete(`${URL}${ACCESS_URL}/${id}`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  })
}

export function addAccess(token, key, namePT, nameEN) {

  return Axios({
    method: 'post',
    url: `${URL}${ACCESS_URL}`,
    headers: {
      "Authorization": `Bearer ${token}`
    },
    data: {
      "key": key,
      "name": {
        "pt": namePT,
        "gb": nameEN
      }
    }
  })
}