import { URL } from "../config";
import Axios from "axios";

export const TERMS_URL = "/terms-conditions";

export function getTerms(token) {
  return Axios.get(`${URL}${TERMS_URL}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function addUpdateTerms(token, termsPT, termsEN) {
  return Axios.post(
    `${URL}${TERMS_URL}`,
    {
      terms: {
        pt: termsPT,
        gb: termsEN,
      },
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}
