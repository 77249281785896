import React, { useEffect, useState } from "react";
import { useSubheader } from "../../_metronic/layout";
import { useSelector } from "react-redux";
import { Loading } from "../components/Loading";
import { getRouteReviews, approveReview, disapproveReview, deleteReview } from "../../redux/crud/ReviewCrud";
import { useParams } from "react-router-dom";
import { RouteReviewPendingTable } from "../components/reviewBoard/RouteReviewPendingTable";
import { RouteReviewApprovedTable } from "../components/reviewBoard/RouteReviewApprovedTable";

export const ReviewsPage = () => {
  const subheader = useSubheader();
  subheader.setTitle("Reviews");
  let { id } = useParams();
  const [loading, setLoading] = useState(false);
  const token = useSelector(state => state.auth.token);

  const [route, setRoute] = useState(null);
  const [pendingReviews, setPendingReviews] = useState([]);
  const [approvedReviews, setApprovedReviews] = useState([]);
  

  useEffect(() => {
    setLoading(true);
    getReviewsLocal();
  }, [])

  const getReviewsLocal = () => {
    getRouteReviews(token, id).then(({ data }) => {
      setLoading(false);
      setRoute(data);

      let approved = [];
      let pending = [];
      data.reviews.forEach(review => {
        if(review.approved){
          approved.push(review);
        } else {
          pending.push(review);
        }
      });
      setPendingReviews(pending);
      setApprovedReviews(approved);
    })
  }

  const approve  = (reviewId) => {
    approveReview(token, route.id, reviewId).then(({data})=>{
      getReviewsLocal();
    })
  }

  const disaprove = (reviewId) => {
    disapproveReview(token, route.id, reviewId).then(({data})=>{
      getReviewsLocal();
    });
  }

  const remove = (reviewId) => {
    deleteReview(token, reviewId).then(({data})=>{
      getReviewsLocal();
    });
  }

  

  return (<>
    <div className="col-lg-12 mb-8">
      {
        route &&
        <RouteReviewPendingTable
          title={route.name.pt}
          subtitle={`Lista de reviews por aprovar`}
          data={pendingReviews}
          approve={approve}
          remove={remove}
        />
      }
    </div>
    <div className="col-lg-12 mb-8">
      {
        route &&
        <RouteReviewApprovedTable
          title={route.name.pt}
          subtitle={`Lista de reviews aprovadas`}
          data={approvedReviews}
          disaprove={disaprove}
          remove={remove}
        />
      }
    </div>
    {loading && (
      <Loading />
    )}
  </>);
};
