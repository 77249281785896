import { URL } from '../config';
import Axios from 'axios';

export const INFORMATION_URL = "/information"

export function getInformation(token) {
  return Axios.get(`${URL}${INFORMATION_URL}`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  });
}

export function addContact(token, position, number, emergency, namePT, nameGB, photo_url, photo_b64) {
  return Axios.post(`${URL}${INFORMATION_URL}/contact`, {
    "position": position,
    "number": number,
    "emergency": emergency,
    "name": {
      "pt": namePT,
      "gb": nameGB
    },
    "photo_url": photo_url,
    "photo_b64": photo_b64
  },
    {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
}

export function updateContact(token, id, position, number, emergency, namePT, nameGB, photo_url, photo_b64) {
  console.log(id, position, number, emergency, namePT, nameGB, photo_url, photo_b64)
  return Axios.post(`${URL}${INFORMATION_URL}/contact/${id}`, {
    "position": position,
    "number": number,
    "emergency": emergency,
    "name": {
      "pt": namePT,
      "gb": nameGB
    },
    "photo_url": photo_url,
    "photo_b64": photo_b64
  },
    {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
}

export function deleteContact(token, id) {
  return Axios.delete(`${URL}${INFORMATION_URL}/contact/${id}`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  })
}

export function addRule(token, position, textPT, textGB) {
  return Axios.post(`${URL}${INFORMATION_URL}/rule`, {
    "position": position,
    "text": {
      "pt": textPT,
      "gb": textGB
    }
  },
    {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
}

export function updateRule(token, id, position, textPT, textGB) {
  return Axios.post(`${URL}${INFORMATION_URL}/rule/${id}`, {
    "position": position,
    "text": {
      "pt": textPT,
      "gb": textGB
    }
  },
    {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
}

export function deleteRule(token, id) {
  return Axios.delete(`${URL}${INFORMATION_URL}/rule/${id}`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  })
}

export function addInformationImage(token, url, b64) {
  return Axios.post(`${URL}${INFORMATION_URL}/image`, {
    "photo_url": url,
    "photo_b64": b64
  },
    {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    }
  )
}

export function deleteInformationImage(token, id) {
  return Axios.delete(`${URL}/photo/${id}`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  })
}

export function updateInformationInfo(token, introPT, introGB){
  return Axios.post(`${URL}${INFORMATION_URL}/info`, {
    "intro": {
      "pt": introPT,
      "gb": introGB
    }
  },
    {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
}

export function updateInformationInfoSignal(token, introPT, introGB){
  return Axios.post(`${URL}${INFORMATION_URL}/info`, {
    "intro_signal": {
      "pt": introPT,
      "gb": introGB
    }
  },
    {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
}

