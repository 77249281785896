import React, { useState } from "react";
import { useSelector } from "react-redux";
import { addRouteGraph, deleteRouteGraph } from '../../../redux/crud/RouteCrud';
import Fab from '@material-ui/core/Fab';
import { Delete } from '@material-ui/icons';
import { ImageInputDialog } from "../ImageInputDialog";
import { ConfirmDialog } from "../ConfirmDialog";
import { URL } from "../../../redux/config";

export const Graph = ({ route, updateRoute }) => {
  const token = useSelector(state => state.auth.token);

  const [imageDialog, setImageDialog] = useState(false);
  const [imageDeleteDialog, setImageDeleteDialog] = useState(false);

  const [deletePhotoId, setDeletePhotoId] = useState(null);

  const handleAddImage = (url, b64) => {
    addRouteGraph(token, route.id, url, b64).then(({ data }) => {
      setImageDialog(false);
      updateRoute();
    })
  }

  const handleDeleteImage = () => {
    deleteRouteGraph(token, route.id, deletePhotoId).then(({ data }) => {
      setDeletePhotoId(null);
      updateRoute();
    })
  }

  return (

    <div id="images" className="col-lg-12">
      <div className="card card-custom card-stretch gutter-b">
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">Gráfico de Altitude</span>
          </h3>
          {
            route.height_graph === null &&
            <div className="card-toolbar">
            <button onClick={() => setImageDialog(true)} className="btn btn-info font-weight-bolder font-size-sm">
              Adicionar Gráfico
            </button>
          </div>
          }
          
        </div>
        <div className="card-body pt-0 pb-4 d-flex flex-row flex-wrap">

          {
            route.height_graph &&
            <div className="d-flex align-items-start m-6">
              <img
                style={{ width: 320, height: 170, objectFit: "fill", marginTop: "2rem" }}
                src={route.height_graph.url ? route.height_graph.url : URL + route.height_graph.content_base64}
              />
              <Fab
                onClick={() => {
                  setDeletePhotoId(route.height_graph.id);
                  setImageDeleteDialog(true);
                }}
                size="small"
                style={{ backgroundColor: "coral", color: "white", marginLeft: "3px" }}
                aria-label="add"
              >
                <Delete />
              </Fab>
            </div>
          }



        </div>
      </div>
      <ImageInputDialog
        open={imageDialog}
        onClose={() => setImageDialog(false)}
        submit={(url, b64) => handleAddImage(url, b64)}
      />
      <ConfirmDialog
        title="Apagar Imagem?"
        open={imageDeleteDialog}
        onclose={() => setImageDeleteDialog(false)}
        yes={() => {
          setImageDeleteDialog(false);
          handleDeleteImage();
        }}
        no={() => {
          setImageDeleteDialog(false);
        }}

      />
    </div>
  );
};
