import React from "react";
import { useSubheader } from "../../_metronic/layout";
import { UsersCard } from "../components/usersBoard/UsersCard";

export const UsersPage = () => {
  const subheader = useSubheader();
  subheader.setTitle("Utilizadores");

  return (<>
    <div className="col-lg-12 mb-8">
      <UsersCard/>
    </div>
  </>);
};
