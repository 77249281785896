import React from "react";
import { useSubheader } from "../../_metronic/layout";
import { CategoryCard } from "../components/contentBoard/CategoryCard";
import { TypeCard } from "../components/contentBoard/TypeCard";
import { TopographyCard } from "../components/contentBoard/TopographyCard";
import { AccessCard } from "../components/contentBoard/AccessCard";
import { FeedbackTypeCard } from "../components/feedbackBoard/FeedbackTypeCard";

export const ContentBoard = () => {
  const subheader = useSubheader();
  subheader.setTitle("Filtros");

  return (<>
    <div className="col-lg-12 mb-8">
      <CategoryCard/>
    </div>
    <div className="col-lg-12 mb-8">
      <TypeCard/>
    </div>
    <div className="col-lg-12 mb-8">
      <TopographyCard/>
    </div>
    <div className="col-lg-12 mb-8">
      <AccessCard/>
    </div>
  </>);
};
