import React from "react";
import CircularProgress from '@material-ui/core/CircularProgress';

export const Loading = () => {
  return (
    <div style={{ height: '100%' }} className="d-flex flex-wrap justify-content-center align-items-center">
      <CircularProgress />
    </div>
  );
};
