import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {getCategories} from "../../redux/crud/CategoryCrud";

export const Table = ({columns, data}) => {

    return (
        <table className="table">
            <thead>
            <tr>
                {
                    columns && columns.map((column) => <th scope="col">{column.label}</th>)
                }
            </tr>
            </thead>
            <tbody>
            {data && data.map((row) => {
                return <tr>
                    {
                        columns && columns.map((column) => {
                            if (column.template) {
                                return <td>{column.template(row)}</td>
                            } else {
                                if (column.primary) {
                                    return <th scope="row">{row[column.name]}</th>
                                } else {
                                    return <td>{row[column.name]}</td>
                                }

                            }
                        })
                    }
                </tr>
            })}
            </tbody>
        </table>
    )
}

export const DatatableDemo = () => {

    const token = useSelector(state => state.auth.token);
    const [categories, setCategories] = useState([]);

    const columns = [
        {
            name: "id",
            label: "#",
            primary: true
        },
        {
            name: "key",
            label: "Key"
        },
        {
            name: "name",
            label: "[PT] Name",
            template: row => row.name.pt
        },
        {
            name: "name",
            label: "[EN] Name",
            template: row => row.name.gb
        },
        {
            name: "actions",
            label: "Actions",
            template: row => {
                return (
                    <>
                        <a style={{fontSize:10}} href="#" className="btn btn-light btn-sm">
                            <i style={{fontSize:10}} className="flaticon2-trash"></i>Remove
                        </a>
                    </>
                )

            }
        }
    ]

    const getCategoriesLocal = () => {
        getCategories(token).then(({data}) => {
            setCategories(data);
        })
    }

    useEffect(() => {
        getCategoriesLocal();
    }, [])

    return (
        <div className="card card-custom">
            <div className="card-header flex-wrap border-0 pt-6 pb-0">
                <div className="card-title">
                    <h3 className="card-label">List of Categories
                        <span className="text-muted pt-2 font-size-sm d-block">You can edit, add and delete categories bellow</span>
                    </h3>
                </div>
            </div>
            <div className="card-body">
                <Table columns={columns} data={categories}/>
            </div>
        </div>
    )


}
