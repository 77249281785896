import React, {useState, useCallback, useEffect} from "react";
import {
    DialogTitle,
    Dialog,
    DialogContent,
    DialogActions,
    TextField,
    Checkbox,
    FormControlLabel
} from "@material-ui/core";
import {useDropzone} from "react-dropzone";
import {URL} from "../../../redux/config";
import ReactQuill from "react-quill";

const quillModules = {
    toolbar: [
        [{header: [1, 2, false]}],
        ["bold", "italic", "underline"],
        [{list: "ordered"}, {list: "bullet"}],
        ["link"],
    ],
};

export const SponsorDialogForm = ({
                                      open,
                                      onclose,
                                      title,
                                      submit,
                                      sponsor,
                                      button,
                                  }) => {
    const [key, setKey] = useState("");
    const [pt, setPt] = useState("");
    const [en, setEn] = useState("");
    const [url, setUrl] = useState(null);
    const [b64, setB64] = useState(null);
    const [havePhoto, setHavePhoto] = useState(false);
    const [imageURL, setImageURL] = useState(null);

    const onDrop = useCallback((acceptedFiles) => {
        var imgFile = acceptedFiles[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            setB64(event.target.result);
            setUrl(null);
        };
        reader.readAsDataURL(imgFile);
    }, []);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        accept: "image/*",
    });

    useEffect(() => {
        if (sponsor) {
            setKey(sponsor.name);
            setPt(sponsor.description.pt);
            setEn(sponsor.description.gb);
            setHavePhoto(sponsor.have_photo);
            if (sponsor.photos) {
                setUrl(sponsor.photos[0].url);
                setImageURL(sponsor.photos[0].content_base64);
            }
        }
    }, [sponsor]);

    return (
        <Dialog open={open} onClose={onclose}>
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <TextField
                    id="outlined-basic"
                    variant="outlined"
                    label="Nome"
                    fullWidth
                    className="mb-3"
                    value={key}
                    onChange={(event) => {
                        setKey(event.target.value);
                    }}
                />
                <span className="text-dark-75 pl-0 text-left font-weight-bolder mr-3 mb-3 font-size-md">
          Descrição PT
        </span>
                <ReactQuill
                    theme="snow"
                    style={{width: "100%", marginBottom: "10px", marginTop: "5px"}}
                    value={pt}
                    onChange={(value) => {
                        setPt(value);
                    }}
                    placeholder="PT"
                    modules={quillModules}
                />
                <span className="text-dark-75 pl-0 text-left font-weight-bolder mr-3 mb-3 font-size-md">
          Descrição EN
        </span>
                <ReactQuill
                    theme="snow"
                    style={{width: "100%", marginBottom: "10px", marginTop: "5px"}}
                    value={en}
                    onChange={(value) => {
                        setEn(value);
                    }}
                    placeholder="EN"
                    modules={quillModules}
                />
                <FormControlLabel control={<Checkbox checked={havePhoto} onChange={(e) => setHavePhoto(e.target.checked)} />} label="Adicionar imagem" />
                {havePhoto && <TextField
                    id="outlined-basic"
                    variant="outlined"
                    label="Icon URL"
                    fullWidth
                    className="mb-3"
                    value={url ? url : ""}
                    onChange={(event) => {
                        setUrl(event.target.value);
                        setB64(null);
                        setImageURL(null);
                    }}
                /> }
                {havePhoto && <div className="drag-input" {...getRootProps()}>
                    <input className="p0" {...getInputProps()} />
                    {isDragActive ? (
                        <p>Arrastar ficheiro para aqui ...</p>
                    ) : (
                        <p>Arrastar ficheiros para aqui ou carregar para selecionar</p>
                    )}
                </div>}

                {havePhoto && <div id="image-preview" className="d-flex mt-4 justify-content-center">
                    {url && <img className="route-image" src={url} alt="URL inválido"/>}
                    {b64 && <img className="route-image" src={b64}/>}
                    {imageURL && <img className="route-image" src={URL + imageURL}/>}
                </div>}
            </DialogContent>
            <DialogActions>
                <button
                    onClick={() => {
                        submit(key, pt, en, url, b64, havePhoto);
                        setKey("");
                        setPt("");
                        setEn("");
                        setUrl(null);
                        setB64(null);
                        setHavePhoto(false);
                    }}
                    className="btn btn-info font-weight-bold font-size-sm mr-5 mb-5"
                    disabled={key === "" || pt === "" || en === ""}
                >
                    {button}
                </button>
            </DialogActions>
        </Dialog>
    );
};
