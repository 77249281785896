import { URL } from '../config';
import Axios from 'axios';

export const FEEDBACK_URL = "/feedback"

export function getFeedback(token) {
  return Axios.get(`${URL}${FEEDBACK_URL}`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }
  );
}


export function getRouteFeedback(token, id) {
  return Axios.get(`${URL}${FEEDBACK_URL}/${id}`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }
  );
}