import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { InputLabel, Select, Input, Chip, MenuItem, Avatar } from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import { getSponsors } from "../../../redux/crud/SponsorCrud";
import { actions } from '../../../redux/ducks/sponsor.duck';
import {URL} from '../../../redux/config'

const SponsorImage = ({sponsor}) => {
  if(sponsor.have_photo){
    return <img src={`${URL}${sponsor.photos[0].content_base64}`} style={{width: 30, height: 30, objectFit: "cover", borderRadius: 4, marginRight: 10}}/>
  }else{
    return <></>
  }
}

const SponsorChip = ({value, sponsors}) => {

  if(sponsors && sponsors.length > 0){
    const sponsor = sponsors.find(sponsor => {
      return sponsor.name === value
    })

    if(sponsor.have_photo){
      return <Chip avatar={<Avatar alt="Natacha" src={`${URL}${sponsor.photos[0].content_base64}`} />} key={value} label={value} className="m-0" />
    }else{
      return <Chip key={value} label={value} className="m-0" />
    }
  }else{
    return <Chip key={value} label={value} className="m-0" />
  }
}

export const SponsorPicker = ({ sponsorFilter, selectSponsor }) => {
  const dispatch = useDispatch();
  const token = useSelector(state => state.auth.token);
  const sponsors = useSelector(state => state.sponsors.sponsors);

  const [keys, setKeys] = useState([]);

  const handleChange = (event) => {
    let nameKeys = event.target.value;
    setKeys(nameKeys);

    const selectedValues = []
    for(const key of nameKeys){
      for(const sponsor of sponsors){
        if(key === sponsor.name){
          selectedValues.push(sponsor)
        }
      }
    }

    selectSponsor(selectedValues);
  };

  const getSponsorsLocal = () => {
    getSponsors(token).then(({ data }) => {
      dispatch(actions.setSponsors(data));
    })
  }

  useEffect(() => {
    if (sponsors.length === 0) {
      getSponsorsLocal();
    }
    let keysTemp = []
    sponsorFilter.forEach((c) => {
      keysTemp.push(c.name)
    })
    setKeys(keysTemp);
  }, []);


  return (
    <div id="category-select" className="d-flex align-items-center mb-10 w-100">
      <span className="text-dark-75 pl-0 text-left font-weight-bolder mr-3 font-size-lg col-3">
        Patrocinadores
      </span>
      <div className="d-flex align-items-center flex-column col-9">
        <FormControl variant="outlined" className="w-100">
          <InputLabel id="demo-mutiple-chip-label">Escolher Patrocinadores</InputLabel>
          <Select
            labelId="demo-mutiple-chip-label"
            id="demo-mutiple-chip"
            multiple
            fullWidth
            variant="outlined"
            value={keys}
            onChange={handleChange}
            input={<Input id="select-multiple-chip" className="p-0" />}
            renderValue={(selected) => (
              <div className="d-flex flex-wrap">
                {selected.map((value) => (
                  <SponsorChip value={value} sponsors={sponsors} />
                ))}
              </div>
            )}
          >
            {sponsors.map((sponsor) => (
              <MenuItem key={sponsor.name} value={sponsor.name}>
                <SponsorImage sponsor={sponsor} />{sponsor.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>



  );
};
