import React, { useEffect, useState } from "react";
import { useSubheader } from "../../_metronic/layout";
import { useSelector, useDispatch } from "react-redux";
import { Loading } from "../components/Loading";
import { actions as reviewActions } from '../../redux/ducks/reviews.duck';
import { getReviews } from "../../redux/crud/ReviewCrud";
import { ReviewTable } from "../components/reviewBoard/ReviewTable";

export const ReviewsBoard = () => {
  const subheader = useSubheader();
  subheader.setTitle("Reviews");

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const token = useSelector(state => state.auth.token);
  const reviews = useSelector(state => state.reviews.reviews);

  useEffect(() => {
    setLoading(true);
    getReviews(token).then(({ data }) => {
      setLoading(false);
      dispatch(reviewActions.setReviews(data));
    })
  }, [])

  return (<>
    <div className="col-lg-12 mb-8">
      {
        reviews &&
        <ReviewTable routes={reviews}/>
      } 
    </div>
    {loading && (
      <Loading />
    )}
  </>);
};
