import React, { useEffect, useState } from "react";
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polyline } from "react-google-maps"

export const Map = withScriptjs(withGoogleMap(({ geojson, color }) => {

  const [coords, setCoords] = useState([]);

  useEffect(()=>{
    let geometry = geojson.features[0].geometry;
    let type = geometry.type;

    if(type === "MultiLineString"){
      let coordinates = [];
      geometry.coordinates.forEach(c => {
        coordinates = [...coordinates, ...c] 
      });;
      let tempCoords = [];
      coordinates.map(c => tempCoords.push({lat: c[1], lng: c[0]}));
      setCoords(tempCoords);
    } else {
      let coordinates = geometry.coordinates;
      let tempCoords = [];
      coordinates.map(c => tempCoords.push({lat: c[1], lng: c[0]}));
      setCoords(tempCoords);
    }
  }, [geojson])

  const getStartCoords = () => {
    let geometry = geojson.features[0].geometry;
    let type = geometry.type;
    let coordinates = [];

    if(type === "MultiLineString"){
      let coordArray = geojson.features[0].geometry.coordinates;
      coordinates = coordArray[0];
    } else {
      coordinates = geojson.features[0].geometry.coordinates;
    }

    
    return {
      lat: coordinates[0][1],
      lng: coordinates[0][0]
    }
  }

  const getEndCoords = () => {
    const coordinates = geojson.features[0].geometry.coordinates;
    return {
      lat: coordinates[coordinates.length - 1][1],
      lng: coordinates[coordinates.length - 1][0]
    }
  }

  return (
    <GoogleMap
      defaultZoom={13}
      defaultCenter={getStartCoords()}
    >
       <Marker
        position={coords[0]} 
      />
      <Marker 
        position={coords[coords.length-1]} 
      /> 
      <Polyline  
        path={coords}
        options={{
          strokeColor: color
        }}
      />
    </GoogleMap>
  );
}));
