import React, { useEffect } from "react";
import { getSponsors, addSponsor, deleteSponsor, editSponsor } from "../../../redux/crud/SponsorCrud";
import { useSelector, useDispatch } from "react-redux";
import { actions } from '../../../redux/ducks/sponsor.duck';
import { SponsorTable } from "./SponsorTable";

export const SponsorCard = () => {
  const dispatch = useDispatch();
  const token = useSelector(state => state.auth.token);
  const sponsors = useSelector(state => state.sponsors.sponsors);

  const getSponsorsLocal = () => {
    getSponsors(token).then(({ data }) => {
      dispatch(actions.setSponsors(data));
    })
  }

  const deleteSponsorLocal = (id) => {
    deleteSponsor(token, id).then(() => {
      getSponsorsLocal();
    })
  }

  const addSponsorLocal = (key, pt, en, url, b64, havePhoto) => {
    addSponsor(token, key, pt, en, url, b64, havePhoto).then(() => {
      getSponsorsLocal();
    });
  }

  const editSponsorLocal = (id, key, pt, en, url, b64, havePhoto) => {
    editSponsor(token, id, key, pt, en, url, b64, havePhoto).then(()=>{
      getSponsorsLocal();
    })
  }

  useEffect(() => {
    getSponsorsLocal();
  }, [])


  return (
    <SponsorTable
      data={sponsors}
      title={"Lista de Patrocinadores"}
      subtitle={"Pode Consultar, Adicionar e Apagar Patrocinadores"}
      addRow={addSponsorLocal}
      deleteRow={deleteSponsorLocal}
      editRow={editSponsorLocal}
      addDialogTitle={"Adicionar Patrocinador"}
    />
   );
};
