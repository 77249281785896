export const actionTypes = {
  SetContacts: "[SetContacts] Action",
  SetRules: "[SetRules] Action",
  SetImage: "[SetImage] Action",
  SetInfo: "[SetInfo] Action"
};

const initialState = {
  contacts: [],
  rules: [],
  image: null,
  info: null
};


export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetContacts: {
      const { contacts } = action.payload;
      return { ...state, contacts };
    }
    case actionTypes.SetRules: {
      const { rules } = action.payload;
      return { ...state, rules }
    }
    case actionTypes.SetImage: {
      const { image } = action.payload;
      return { ...state, image }
    }
    case actionTypes.SetInfo:
      const { info } = action.payload;
      return { ...state, info }
    default:
      return state;
  }
}

export const actions = {
  setContacts: (contacts) => ({ type: actionTypes.SetContacts, payload: { contacts } }),
  setRules: (rules) => ({ type: actionTypes.SetRules, payload: { rules } }),
  setImage: (image) => ({ type: actionTypes.SetImage, payload: { image } }),
  setInfo: (info) => ({ type: actionTypes.SetInfo, payload: { info } })
};

