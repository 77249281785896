import React, { useState } from "react";
import { useSelector } from "react-redux";
import { deleteRoute } from '../../../redux/crud/RouteCrud';
import { ConfirmDialog } from "../ConfirmDialog";
import { useHistory } from "react-router-dom";

export const DeleteRoute = ({ route }) => {
  const token = useSelector(state => state.auth.token);
  const history = useHistory();

  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const handleDeleteRow = () => {
    deleteRoute(token, route.id).then(()=>{
      history.push("/percursos")
    })
  }


  return (

    <div id="delete" className="col-lg-12">
      <div className="card card-custom card-stretch gutter-b">
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">Apagar Rota</span>
          </h3>
        </div>
        <div className="card-body pt-0 pb-4 d-flex flex-row flex-wrap justify-content-end">
          <div className="d-flex justify-content-end">
            <button
              onClick={() => setDeleteConfirm(true)}
              id="add-button"
              className="btn btn-warning font-weight-bolder font-size-lg mb-5"
            >
              Apagar
            </button>
          </div>
        </div>
      </div>

      <ConfirmDialog
        title={`Tem a certeza que quer apagar a rota '${route && route.name.pt}'?`}
        open={deleteConfirm}
        onclose={() => setDeleteConfirm(false)}
        yes={() => {
          setDeleteConfirm(false);
          handleDeleteRow();
        }}
        no={() => {
          setDeleteConfirm(false);
        }}

      />
    </div>
  );
};
