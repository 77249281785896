import Axios from "axios";
import {URL} from "../config";

export function addUpdateText(token, key, content) {
    return Axios({
        method: 'post',
        url: `${URL}/text/${key}`,
        headers: {
            "Authorization": `Bearer ${token}`
        },
        data: {
            content
        }
    })
}

export function getText(token, key) {
    return Axios.get(`${URL}/text/${key}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }
    );
}
