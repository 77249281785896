export const actionTypes = {
  SetTypes: "[SetTypes] Action"
};

const initialState = {
  types: [],
};


export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetTypes: {
      const { types } = action.payload;
      return { ...state, types };
    }
    default:
      return state;
  }
}

export const actions = {
  setTypes: (types) => ({ type: actionTypes.SetTypes, payload: { types } })
};

