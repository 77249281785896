export const actionTypes = {
  SetTerms: "[SetTerms] Action",
};

const initialState = {
  terms: null,
};


export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetTerms: {
      const { terms } = action.payload;
      return { ...state, terms };
    }
    default:
      return state;
  }
}

export const actions = {
  setTerms: (terms) => ({ type: actionTypes.SetTerms, payload: { terms } }),
};

