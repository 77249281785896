import { URL } from '../config';
import Axios from 'axios';

export const ROUTE_URL = "/route";

export function getRoutes(token) {
  return Axios.get(URL + ROUTE_URL, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }
  );
}

export function addRoute(token, geojson, namePT, nameEN, subtitlePT, subtitleEN, descriptionPT,
  descriptionEN, lengthMetric, lengthImperial, heightMetric, heightImperial, color, position) {

  return Axios({
    method: 'post',
    url: URL + ROUTE_URL,
    headers: {
      "Authorization": `Bearer ${token}`
    },
    data: {
      "geojson": geojson !== "" ? JSON.parse(geojson) : "",
      "name": {
        "pt": namePT,
        "gb": nameEN
      },
      "subtitle": {
        "pt": subtitlePT,
        "gb": subtitleEN
      },
      "description": {
        "pt": descriptionPT,
        "gb": descriptionEN
      },
      "length": {
        "metric": lengthMetric,
        "imperial": lengthImperial
      },
      "height": {
        "metric": heightMetric,
        "imperial": heightImperial
      },
      "color": color,
      "position": position
    }
  })
}

export function getRoute(token, id) {
  return Axios.get(`${URL}${ROUTE_URL}/${id}`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }
  );
}

export function getRouteGeojson(token, id) {
  return Axios.get(`${URL}${ROUTE_URL}/${id}/geojson`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }
  );
}


export function updateRoute(token, id, namePT, nameEN, subtitlePT, subtitleEN, descriptionPT,
  descriptionEN, lengthMetric, lengthImperial, heightMetric, heightImperial, color, position) {

  return Axios({
    method: 'post',
    url: `${URL}${ROUTE_URL}/${id}`,
    headers: {
      "Authorization": `Bearer ${token}`
    },
    data: {
      "name": {
        "pt": namePT,
        "gb": nameEN
      },
      "subtitle": {
        "pt": subtitlePT,
        "gb": subtitleEN
      },
      "description": {
        "pt": descriptionPT,
        "gb": descriptionEN
      },
      "length": {
        "metric": lengthMetric,
        "imperial": lengthImperial
      },
      "height": {
        "metric": heightMetric,
        "imperial": heightImperial
      },
      "color": color,
      "position": position
    }
  })
}

export function updateRouteGeojson(token, id, geojson) {

  return Axios({
    method: 'post',
    url: `${URL}${ROUTE_URL}/${id}`,
    headers: {
      "Authorization": `Bearer ${token}`
    },
    data: {
      "geojson": JSON.parse(geojson)
    }
  })
}

export function addRoutePhoto(token, id, url, b64, position) {
  return Axios({
    method: 'post',
    url: `${URL}${ROUTE_URL}/${id}/photos`,
    headers: {
      "Authorization": `Bearer ${token}`
    },
    data: {
      "photos": [
        {
          "photo_url": url,
          "photo_b64": b64,
          "position": position
        }
      ]
    }
  })
}

export function deleteRoutePhoto(token, id, photoId) {
  return Axios({
    method: 'delete',
    url: `${URL}${ROUTE_URL}/${id}/photos/${photoId}`,
    headers: {
      "Authorization": `Bearer ${token}`
    }
  })
}

export function updatePhotoPosition(token, id, position) {
  return Axios({
    method: 'post',
    url: `${URL}/photo/${id}`,
    headers: {
      "Authorization": `Bearer ${token}`
    },
    data: {
      "position": position
    }
  })
}

export function updateRouteCategory(token, id, categoryIds) {
  return Axios({
    method: 'post',
    url: `${URL}${ROUTE_URL}/${id}/category`,
    headers: {
      "Authorization": `Bearer ${token}`
    },
    data: {
      "ids": [...categoryIds]
    }
  })
}

export function updateRouteType(token, id, typeIds) {
  return Axios({
    method: 'post',
    url: `${URL}${ROUTE_URL}/${id}/type`,
    headers: {
      "Authorization": `Bearer ${token}`
    },
    data: {
      "ids": [...typeIds]
    }
  })
}

export function updateRouteTopography(token, id, topographyIds) {
  return Axios({
    method: 'post',
    url: `${URL}${ROUTE_URL}/${id}/topography`,
    headers: {
      "Authorization": `Bearer ${token}`
    },
    data: {
      "ids": [...topographyIds]
    }
  })
}

export function updateRouteAccess(token, id, accessIds) {
  return Axios({
    method: 'post',
    url: `${URL}${ROUTE_URL}/${id}/access`,
    headers: {
      "Authorization": `Bearer ${token}`
    },
    data: {
      "ids": [...accessIds]
    }
  })
}


export function updateRouteAttributes(token, id, attributeIds) {
  return Axios({
    method: 'post',
    url: `${URL}${ROUTE_URL}/${id}/attribute`,
    headers: {
      "Authorization": `Bearer ${token}`
    },
    data: {
      "ids": [...attributeIds]
    }
  })
}


export function addRouteGraph(token, id, url, b64) {
  return Axios({
    method: 'post',
    url: `${URL}${ROUTE_URL}/${id}/graph`,
    headers: {
      "Authorization": `Bearer ${token}`
    },
    data: {
      "height_graph": [
        {
          "url": url,
          "photo_b64": b64
        }
      ]
    }
  })
}

export function deleteRouteGraph(token, id, graphId) {
  return Axios({
    method: 'delete',
    url: `${URL}${ROUTE_URL}/${id}/graph/${graphId}`,
    headers: {
      "Authorization": `Bearer ${token}`
    }
  })
}

export function deleteRouteTip(token, routeId, tipId) {
  return Axios({
    method: 'delete',
    url: `${URL}${ROUTE_URL}/${routeId}/tip/${tipId}`,
    headers: {
      "Authorization": `Bearer ${token}`
    }
  })
}


export function addRouteTip(token, id, pt, gb) {
  return Axios({
    method: 'post',
    url: `${URL}${ROUTE_URL}/${id}/tip`,
    headers: {
      "Authorization": `Bearer ${token}`
    },
    data: {
      "tips": [
        {
          "pt": pt,
          "gb": gb
        }
      ]
    }
  })
}


export function updateRouteTip(token, routeId, tipID, pt, gb) {
  return Axios({
    method: 'post',
    url: `${URL}${ROUTE_URL}/${routeId}/tip/${tipID}`,
    headers: {
      "Authorization": `Bearer ${token}`
    },
    data: {
      "tips": [
        {
          "pt": pt,
          "gb": gb
        }
      ]
    }
  })
}

export function updateRouteSponsors(token, routeID, sponsorIDs) {
  return Axios.post(`${URL}${ROUTE_URL}/${routeID}/sponsor`,
  {
    "ids": [...sponsorIDs]
  },
  {
    headers: {
      "Authorization": `Bearer ${token}`
    },
  }
  )
}

export function deleteRoute(token, routeID) {
  return Axios.delete(`${URL}${ROUTE_URL}/${routeID}`,
    {
      headers: {
        "Authorization": `Bearer ${token}`
      },
    }
  )
}
