import React, { useState } from "react";
import { useSelector } from "react-redux";
import { CategoryPicker } from "./CategoryPicker";
import {
  updateRouteCategory, updateRouteType, updateRouteTopography,
  updateRouteAccess, updateRouteAttributes, updateRouteSponsors
} from '../../../redux/crud/RouteCrud';
import { TypePicker } from "./TypePicker";
import { TopographyPicker } from "./TopographyPicker";
import { AccessPicker } from "./AccessPicker";
import { AttributePicker } from "./AttributePicker";
import { SponsorPicker } from "./SponsorPicker";



export const Filters = ({ route, updateRoute }) => {
  const token = useSelector(state => state.auth.token);
  const [touched, setTouched] = useState(false)
  const [selectedCategories, selectCategories] = useState(route.category);
  const [selectedTypes, selectTypes] = useState(route.type);
  const [selectedTopographies, selectTopographies] = useState(route.topography);
  const [selectedAccess, selectAccess] = useState(route.access);
  const [selectedAttributes, selectAttribute] = useState(route.attributes);
  const [selectedSponsors, selectSponsor] = useState(route.sponsors)

  const save = () => {
    saveAllFilters().then(() => {
      updateRoute();
      setTouched(false);
    })
  }

  async function saveAllFilters() {
    let categoryIDs = [];
    selectedCategories.forEach((category) => {
      categoryIDs.push(category.id);
    })
    updateRouteCategory(token, route.id, categoryIDs);

    let typeIDs = [];
    selectedTypes.forEach((type) => {
      typeIDs.push(type.id);
    })
    updateRouteType(token, route.id, typeIDs);


    let topographyIDs = [];
    selectedTopographies.forEach((type) => {
      topographyIDs.push(type.id);
    })
    updateRouteTopography(token, route.id, topographyIDs);

    let accessIDs = [];
    selectedAccess.forEach((access) => {
      accessIDs.push(access.id);
    })
    updateRouteAccess(token, route.id, accessIDs);

    let attributeIDs = [];
    selectedAttributes.forEach((attribute) => {
      attributeIDs.push(attribute.id);
    })
    updateRouteAttributes(token, route.id, attributeIDs);

    let sponsorIDs = [];
    selectedSponsors.forEach((sponsor)=> {
      sponsorIDs.push(sponsor.id);
    })
    updateRouteSponsors(token, route.id, sponsorIDs);
  }



  return (
    <div id="filters" className="col-lg-12">
      <div className="card card-custom card-stretch gutter-b">
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">Customização</span>
          </h3>
        </div>
        <div className="card-body pt-0 pb-3 d-flex flex-row flex-wrap">
          <CategoryPicker
            categoryFilter={route.category}
            selectCategories={(value) => { selectCategories(value); setTouched(true); }}
          />
          <TypePicker
            typeFilter={route.type}
            selectTypes={(value) => { selectTypes(value); setTouched(true); }}
          />
          <TopographyPicker
            topographyFilter={route.topography}
            selectTopographies={(value) => { selectTopographies(value); setTouched(true); }}
          />
          <AccessPicker
            accessFilter={route.access}
            selectAccess={(value) => { selectAccess(value); setTouched(true); }}
          />
          <AttributePicker
            attributeFilter={route.attributes}
            selectAttribute={(value) => { selectAttribute(value); setTouched(true); }}
          />
          <SponsorPicker
            sponsorFilter={route.sponsors}
            selectSponsor={(value) => { selectSponsor(value); setTouched(true); }}
          />
          {
            touched &&
            <div className="d-flex justify-content-end w-100 mt-10">
              <button
                onClick={save}
                id="add-button"
                className="btn btn-info font-weight-bolder font-size-lg mb-5"
              >
                Guardar
              </button>
            </div>
          }
        </div>
      </div>
    </div>



  );
};
