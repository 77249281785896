import { URL } from '../config';
import Axios from 'axios';


export function getSponsors(token) {
  return Axios.get(`${URL}/sponsor`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  })
}

export function addSponsor(token, key, namePT, nameEN, url, b64, have_photo) {
  return Axios({
    method: 'post',
    url: `${URL}/sponsor`,
    headers: {
      "Authorization": `Bearer ${token}`
    },
    data: {
      "name": key,
      "description": {
        "pt": namePT,
        "gb": nameEN
      },
      "photo_url": url,
      "photo_b64": b64,
      have_photo
    }
  })
}

export function deleteSponsor(token, id) {
  return Axios.delete(`${URL}/sponsor/${id}`,{
    headers: {
      "Authorization": `Bearer ${token}`
    }
  })
}

export function editSponsor(token, id, key, namePT, nameEN, url, b64, have_photo) {
  return Axios({
    method: 'post',
    url: `${URL}/sponsor/${id}`,
    headers: {
      "Authorization": `Bearer ${token}`
    },
    data: {
      "name": key,
      "description": {
        "pt": namePT,
        "gb": nameEN
      },
      "photo_url": url,
      "photo_b64": b64,
      have_photo
    }
  })
}
