import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {TextField} from "@material-ui/core";
import {getText, addUpdateText} from "../../redux/crud/TextCrud";

const TitleInput = ({code, label = "Titulo"}) => {

    const token = useSelector((state) => state.auth.token);
    const [titlePt, setTitlePt] = useState("")
    const [titleEn, setTitleEn] = useState("")
    const [loading, setLoading] = useState(false)
    const [loadingData, setLoadingData] = useState(false)

    useEffect( () => {
        setLoadingData(true)
        if(code){
            getText(token, code).then(({data}) => {
                setTitleEn(data['content']['en'])
                setTitlePt(data['content']['pt'])
                setLoadingData(false)
            }).catch(() => {
                setLoadingData(false)
            })
        }
    }, [code])

    const save = async () => {
        setLoading(true)
        addUpdateText(token, code, {"en": titleEn, "pt": titlePt}).then(() => {
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })
    }

    if(loadingData){
        return <div></div>
    }else{
        return (
            <div id="name-input" className="d-flex align-items-center mb-10">
                <div className="d-flex align-items-center flex-column col-5">
                    <TextField
                        variant="outlined"
                        label={`${label} PT`}
                        fullWidth
                        className="mb-3"
                        value={titlePt}
                        onChange={(event) => setTitlePt(event.target.value)}
                    />
                </div>
                <div className="d-flex align-items-center flex-column col-5">
                    <TextField
                        variant="outlined"
                        label={`${label} EN`}
                        fullWidth
                        className="mb-3"
                        value={titleEn}
                        onChange={(event) => setTitleEn(event.target.value)}
                    />
                </div>
                <div className="d-flex justify-content-end ">
                    <button
                        id="add-button"
                        className="btn btn-info font-weight-bolder font-size-lg mb-2 ml-5"
                        onClick={save}
                    >
                        Guardar {loading && <i className="fa fa-spinner fa-spin"></i>}
                    </button>
                </div>
                <hr/>
            </div>
        )
    }


}

export default TitleInput
