import { URL } from '../config';
import Axios from 'axios';

export const REVIEW_URL = "/review"

export function getReviews(token) {
  return Axios.get(`${URL}${REVIEW_URL}`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  });
}

export function getRouteReviews(token, route_id) {
  return Axios.get(`${URL}${REVIEW_URL}/${route_id}`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  });
}

export function approveReview(token, route_id, review_id) {
  return Axios.post(`${URL}${REVIEW_URL}/${route_id}/approve/${review_id}`, {}, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  })
}

export function disapproveReview(token, route_id, review_id) {
  return Axios.post(`${URL}${REVIEW_URL}/${route_id}/disapprove/${review_id}`, {}, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  })
}

export function deleteReview(token, review_id) {
  return Axios.delete(`${URL}${REVIEW_URL}/${review_id}`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  })
}
