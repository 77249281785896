import React, { useEffect, useState } from "react";
import { useSubheader } from "../../_metronic/layout";
import { useSelector } from "react-redux";
import { Loading } from "../components/Loading";
import { getRouteFeedback } from "../../redux/crud/FeedbackCrud";
import { useParams } from "react-router-dom";
import { RouteFeedbackTable } from "../components/feedbackBoard/RouteFeedbackTable";

export const FeedbackPage = () => {
  const subheader = useSubheader();
  subheader.setTitle("Feedback");
  let { id } = useParams();
  const [loading, setLoading] = useState(false);
  const token = useSelector(state => state.auth.token);

  const [route, setRoute] = useState(null)

  useEffect(() => {
    setLoading(true);
    getRouteFeedback(token, id).then(({ data }) => {
      setLoading(false)
      setRoute(data);
    })
  }, [])

  return (<>
    <div className="col-lg-12 mb-8">
      {
        route &&
        <RouteFeedbackTable
          title={route.name.pt}
          subtitle={`Lista de feedback dado`}
          data={route.feedback}
        />
      }
    
    </div>
    {loading && (
      <Loading />
    )}
  </>);
};
