import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { updateRoute } from "../../../redux/crud/RouteCrud";
import { TextField } from "@material-ui/core";
import { ColorPicker } from "../ColorPicker";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./quill.css";

export const TrailInfo = ({ route, updateRouteLocal }) => {
  const token = useSelector((state) => state.auth.token);

  const [namePT, setNamePT] = useState("");
  const [nameEN, setNameEN] = useState("");
  const [subtitlePT, setSubtitlePT] = useState("");
  const [subtitleEN, setSubtitleEN] = useState("");
  const [descriptionPT, setDescriptionPT] = useState("");
  const [descriptionEN, setDescriptionEN] = useState("");
  const [lengthMetric, setLengthMetric] = useState("");
  const [lengthImperial, setLengthImperial] = useState("");
  const [heightMetric, setHeightMetric] = useState("");
  const [heightImperial, setHeightImperial] = useState("");
  const [color, setColor] = useState("#0199B4");
  const [position, setPosition] = useState("");

  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (route) {
      setNamePT(route.name["pt"]);
      setNameEN(route.name["gb"]);
      setSubtitlePT(route.subtitle["pt"]);
      setSubtitleEN(route.subtitle["gb"]);
      setDescriptionPT(route.description["pt"]);
      setDescriptionEN(route.description["gb"]);
      setLengthMetric(route.length.metric);
      setLengthImperial(route.length.imperial);
      setHeightMetric(route.height.metric);
      setHeightImperial(route.height.imperial);
      setColor(route.color);
      setPosition(route.position);
    }
  }, [route]);

  const save = () => {
    updateRoute(
      token,
      route.id,
      namePT,
      nameEN,
      subtitlePT,
      subtitleEN,
      descriptionPT,
      descriptionEN,
      lengthMetric,
      lengthImperial,
      heightMetric,
      heightImperial,
      color,
      position
    ).then(() => {
      setTouched(false);
      updateRouteLocal();
    });
  };

  const quillModules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
    ],
  };

  return (
    <>
      {route && (
        <div id="route" className="col-lg-6 col-md-12">
          <div className="card card-custom card-stretch gutter-b">
            <div className="card-header border-0 py-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label font-weight-bolder text-dark">
                  {route.name["pt"]}
                </span>
              </h3>
            </div>
            <div className="card-body pt-0 pb-3">
              <div id="name-input" className="d-flex align-items-center mb-10">
                <span className="text-dark-75 pl-0 text-left font-weight-bolder mr-3 font-size-lg col-3">
                  Nome
                </span>
                <div className="d-flex align-items-center flex-column col-9">
                  <TextField
                    variant="outlined"
                    label="PT"
                    fullWidth
                    className="mb-3"
                    value={namePT}
                    onChange={(event) => {
                      if (!touched) {
                        setTouched(true);
                      }
                      setNamePT(event.target.value);
                    }}
                  />
                  <TextField
                    variant="outlined"
                    label="EN"
                    fullWidth
                    value={nameEN}
                    onChange={(event) => {
                      if (!touched) {
                        setTouched(true);
                      }
                      setNameEN(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div
                id="subtitle-input"
                className="d-flex align-items-center mb-10"
              >
                <span className="text-dark-75 pl-0 text-left font-weight-bolder mr-3 font-size-lg col-3">
                  Subtítulo
                </span>
                <div className="d-flex align-items-center flex-column col-9">
                  <TextField
                    variant="outlined"
                    label="PT"
                    fullWidth
                    className="mb-3"
                    value={subtitlePT}
                    onChange={(event) => {
                      if (!touched) {
                        setTouched(true);
                      }
                      setSubtitlePT(event.target.value);
                    }}
                  />
                  <TextField
                    variant="outlined"
                    label="EN"
                    fullWidth
                    value={subtitleEN}
                    onChange={(event) => {
                      if (!touched) {
                        setTouched(true);
                      }
                      setSubtitleEN(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div
                id="description_input"
                className="d-flex align-items-center mb-10"
              >
                <span className="text-dark-75 pl-0 font-weight-bolder mr-3 font-size-lg col-3">
                  Descrição
                </span>
                <div className="d-flex align-items-center flex-column col-9">
                  <ReactQuill
                    theme="snow"
                    style={{ width: "100%", marginBottom: "10px" }}
                    value={descriptionPT}
                    onChange={(value) => {
                      if (!touched) {
                        setTouched(true);
                      }
                      setDescriptionPT(value);
                    }}
                    placeholder="PT"
                    modules={quillModules}
                  />
                  <ReactQuill
                    theme="snow"
                    style={{ width: "100%" }}
                    value={descriptionEN}
                    onChange={(value) => {
                      if (!touched) {
                        setTouched(true);
                      }
                      setDescriptionEN(value);
                    }}
                    placeholder="PT"
                    modules={quillModules}
                  />
                </div>
              </div>
              <div
                id="position-input"
                className="d-flex align-items-center mb-10"
              >
                <span className="text-dark-75 pl-0 font-weight-bolder mr-3 font-size-lg col-3">
                  Posição
                </span>
                <div className="d-flex align-items-center flex-column col-9 ">
                  <TextField
                    variant="outlined"
                    label="Posição"
                    fullWidth
                    className="mb-3"
                    type="number"
                    value={position}
                    onChange={(event) => {
                      if (!touched) {
                        setTouched(true);
                      }
                      setPosition(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div
                id="length-input"
                className="d-flex align-items-center mb-10"
              >
                <span className="text-dark-75 pl-0 font-weight-bolder mr-3 font-size-lg col-3">
                  Comprimento
                </span>
                <div className="d-flex align-items-center flex-column col-9 ">
                  <TextField
                    variant="outlined"
                    label="Kilometros"
                    fullWidth
                    className="mb-3"
                    type="number"
                    value={lengthMetric}
                    onChange={(event) => {
                      if (!touched) {
                        setTouched(true);
                      }
                      setLengthMetric(
                        (Math.round(event.target.value * 100) / 100).toFixed(2)
                      );
                      setLengthImperial(
                        (
                          Math.round(event.target.value * 0.621371192 * 100) /
                          100
                        ).toFixed(2)
                      );
                    }}
                  />
                  <TextField
                    variant="outlined"
                    label="Milhas"
                    fullWidth
                    type="number"
                    value={lengthImperial}
                    onChange={(event) => {
                      if (!touched) {
                        setTouched(true);
                      }
                      setLengthImperial(
                        (Math.round(event.target.value * 100) / 100).toFixed(2)
                      );
                      setLengthMetric(
                        (
                          Math.round((event.target.value / 0.621371192) * 100) /
                          100
                        ).toFixed(2)
                      );
                    }}
                  />
                </div>
              </div>
              <div
                id="height-input"
                className="d-flex align-items-center mb-10"
              >
                <span className="text-dark-75 pl-0 font-weight-bolder mr-3 font-size-lg col-3">
                  Altura
                </span>
                <div className="d-flex align-items-center flex-column col-9 ">
                  <TextField
                    variant="outlined"
                    label="Metros"
                    fullWidth
                    className="mb-3"
                    type="number"
                    value={heightMetric}
                    onChange={(event) => {
                      if (!touched) {
                        setTouched(true);
                      }
                      setHeightMetric(
                        (Math.round(event.target.value * 100) / 100).toFixed(2)
                      );
                      setHeightImperial(
                        (
                          Math.round(event.target.value * 3.2808399 * 100) / 100
                        ).toFixed(2)
                      );
                    }}
                  />
                  <TextField
                    variant="outlined"
                    label="Feet"
                    fullWidth
                    type="number"
                    value={heightImperial}
                    onChange={(event) => {
                      if (!touched) {
                        setTouched(true);
                      }
                      setHeightImperial(
                        (Math.round(event.target.value * 100) / 100).toFixed(2)
                      );
                      setHeightMetric(
                        (
                          Math.round((event.target.value / 3.2808399) * 100) /
                          100
                        ).toFixed(2)
                      );
                    }}
                  />
                </div>
              </div>
              <div
                id="color-picker"
                className="d-flex align-items-center mb-10"
              >
                <span className="text-dark-75 pl-0 font-weight-bolder mr-3 font-size-lg col-3">
                  Cor
                </span>
                <div className="d-flex flex-column col-9 ">
                  <ColorPicker
                    currentColor={color}
                    setColor={(color) => {
                      setColor(color);
                      if (!touched) {
                        setTouched(true);
                      }
                    }}
                  />
                </div>
              </div>
              {touched && (
                <div className="d-flex justify-content-end mt-10">
                  <button
                    onClick={save}
                    id="add-button"
                    className="btn btn-info font-weight-bolder font-size-lg mb-5"
                  >
                    Guardar Alterações
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
