import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { InputLabel, Select, Input, Chip, MenuItem } from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import { getCategories } from "../../../redux/crud/CategoryCrud";
import { actions as categoryActions } from '../../../redux/ducks/categories.duck';

export const CategoryPicker = ({ categoryFilter, selectCategories }) => {
  const dispatch = useDispatch();
  const token = useSelector(state => state.auth.token);
  const categories = useSelector(state => state.categories.categories);

  const [keys, setKeys] = useState([]);

  const handleChange = (event) => {
    let nameKeys = event.target.value;
    setKeys(nameKeys);

    let selectedValues = categories.filter((value) =>
      nameKeys.includes(value.name.pt)
    );

    selectCategories(selectedValues);
  };

  const getCategoriesLocal = () => {
    getCategories(token).then(({ data }) => {
      dispatch(categoryActions.setCategories(data));
    })
  }

  useEffect(() => {
    if (categories.length === 0) {
      getCategoriesLocal();
    }
    let keysTemp = []
    categoryFilter.forEach((c) => {
      keysTemp.push(c.name.pt)
    })
    setKeys(keysTemp);
  }, []);


  return (
    <div id="category-select" className="d-flex align-items-center mb-10 w-100">
      <span className="text-dark-75 pl-0 text-left font-weight-bolder mr-3 font-size-lg col-3">
        Localização
      </span>
      <div className="d-flex align-items-center flex-column col-9">
        <FormControl variant="outlined" className="w-100">
          <InputLabel id="demo-mutiple-chip-label">Escolher Localizações</InputLabel>
          <Select
            labelId="demo-mutiple-chip-label"
            id="demo-mutiple-chip"
            multiple
            fullWidth
            variant="outlined"
            value={keys}
            onChange={handleChange}
            input={<Input id="select-multiple-chip" className="p-0" />}
            renderValue={(selected) => (
              <div className="d-flex flex-wrap">
                {selected.map((value) => (
                  <Chip key={value} label={value} className="m-0" />
                ))}
              </div>
            )}
          >
            {categories.map((category) => (
              <MenuItem key={category.key} value={category.name.pt}>
                {category.name.pt}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>



  );
};
