import React, { useEffect } from "react";
import { useSubheader } from "../../_metronic/layout";
import { useDispatch, useSelector } from "react-redux";
import { TermsCard } from "../components/termsAndConditionsPage/TermsCard";
import { getTerms } from "../../redux/crud/TermsCrud";
import { actions as termsActions } from '../../redux/ducks/termsAndConditions.duck';

export const TermsAndConditionsPage = () => {
  const subheader = useSubheader();
  subheader.setTitle("Informações");

  const dispatch = useDispatch();
  const token = useSelector(({ auth }) => auth.token);

  useEffect(() => {
    getTermsAndConditionsLocal();
  }, []);

  const getTermsAndConditionsLocal = () => {
    getTerms(token).then(({data})=>{
      dispatch(termsActions.setTerms(data));
    })
  }

  return (<>
    <div className="col-lg-12 mb-8">
      <TermsCard updateTerms={getTermsAndConditionsLocal}/>
    </div>    
  </>);
};
