import React, { useEffect } from "react";
import { getTopographies, addTopography, deleteTopography } from "../../../redux/crud/TopographyCrud"
import { useSelector, useDispatch } from "react-redux";
import { actions } from '../../../redux/ducks/topographies.duck';
import { TopographyTable } from "./TopographyTable";


export const TopographyCard = () => {
  const dispatch = useDispatch();
  const token = useSelector(state => state.auth.token);
  const topographies = useSelector(state => state.topographies.topographies);
  

  const getTopographiesLocal = () => {
    getTopographies(token).then(({ data }) => {
      dispatch(actions.setTopographies(data));
    })
  }

  const deleteTopographyLocal = (id) => {
    deleteTopography(token, id).then(() => {
      getTopographiesLocal();
    })
  }

  const addTopographyLocal = (key, pt, en, ptFeet, enFeet) => {
    addTopography(token, key, pt, en, ptFeet, enFeet).then(() => {
      getTopographiesLocal();
    });
  }

  useEffect(() => {
    getTopographiesLocal();
  }, [])


  return (
    <TopographyTable 
      data={topographies}
      title={"Lista de Topografias"}
      subtitle={"Pode Consultar, Adicionar e Apagar Topografias"}
      addRow={addTopographyLocal}
      deleteRow={deleteTopographyLocal}
      addDialogTitle={"Adicionar Topografia"}
    />
   );
};
